import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TestLinkPage = () => {
  const [password, setPassword] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  // The correct password - NAE2025
  const correctPassword = 'NAE2025';
  
  // Target activation time: February 27, 2025, 9:30 AM
  const targetTime = new Date('2025-02-27T09:00:00').getTime();
  
  useEffect(() => {
    const checkTime = () => {
      const now = new Date().getTime();
      const difference = targetTime - now;
      
      if (difference <= 0) {
        // Time has passed, activate the input
        setIsActive(true);
        setTimeLeft('Test link is now available!');
      } else {
        // Calculate time remaining
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        
        setTimeLeft(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
        setIsActive(false);
        
        // Check if the cleanup has already been performed
        // if (!localStorage.getItem('cleanupPerformed')) {
          // Run your cleanup code
          localStorage.removeItem('testData');
          localStorage.removeItem('responseData');
          localStorage.removeItem('subjects');
          localStorage.removeItem('hasStarted');
          localStorage.removeItem('startTime');
          
        //   // Set a flag indicating cleanup has been performed
        //   localStorage.setItem('cleanupPerformed', 'true');
          
        //   console.log('Cleanup performed successfully');
        // } else {
        //   console.log('Cleanup already performed previously');
        // }
      }
    };
    
    // Check immediately
    checkTime();
    
    // Set up interval to check every second
    const interval = setInterval(checkTime, 1000);
    
    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);
  
  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setError('');
  };
  
  const handleSubmit = (e:any) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsCorrect(true);
    } else {
      setError('Incorrect password. Please try again.');
    }
  };
  
  const openTestLink = () => {
    // Navigate to the specified route path
    navigate('/TalentTest2025/MainExam');
  };
  
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg p-6 md:p-8 w-full max-w-md">
        <h1 className="text-2xl font-bold text-center text-blue-600 mb-6">Online Test Portal</h1>
        
        {/* Timer Section */}
        <div className="mb-6">
          <div className="bg-blue-50 rounded-lg p-4 text-center">
            <h2 className="text-lg font-semibold text-blue-800 mb-2">
              {isActive ? 'Test is now available!' : 'Test will be available at 9:30 AM'}
            </h2>
            <div className="text-3xl font-mono font-bold text-blue-600">
              {timeLeft}
            </div>
          </div>
        </div>
        
        {/* Password Section */}
        {!isCorrect ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Enter Password to Access Test
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                disabled={!isActive}
                className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${!isActive ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}`}
                placeholder={isActive ? "Enter password" : "Available after 9:30 AM"}
              />
              {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
            </div>
            <p className='text-slate-500 '>Password is <span className='text-xl text-red-400 font-bold'>NAE2025</span></p>
            <button
              type="submit"
              disabled={!isActive}
              className={`w-full py-2 px-4 rounded-md font-medium text-white ${
                isActive ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'
              }`}
            >
              Verify Password
            </button>
          </form>
        ) : (
          <div className="space-y-4">
            <div className="bg-green-50 p-4 rounded-md">
              <p className="text-green-800 font-medium text-center">Password verified successfully!</p>
            </div>
            <button
              onClick={openTestLink}
              className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 rounded-md font-medium text-white"
            >
              Open Test Link
            </button>
          </div>
        )}
        
        <div className="mt-6 text-sm text-gray-500 text-center">
          <p>If you encounter any issues, please contact your administrator.</p>
        </div>
      </div>
    </div>
  );
};

export default TestLinkPage;