import React, { useState, useEffect } from 'react';

// Define TypeScript interfaces
interface Marks {
  Mathematics: number;
  Biology: number;
  Chemistry: number;
  Physics: number;
  "Social Science": number;
}

interface Participant {
  participant_name: string;
  roll_number: string;
  marks: Marks;
  total_time: number;
  total_marks: number;
  maths_marks: number;
  science_marks: number;
  physics_marks: number;
}

interface RankingListProps {
  testID: number;
}

const API_URL = process.env.REACT_APP_API_URL;

const RankingList: React.FC<RankingListProps> = ({ testID }) => {
  // State variables for data
  const [rankingData, setRankingData] = useState<Participant[]>([]);
  const [participantName, setParticipantName] = useState<string>("");
  const [participantRollNumber, setParticipantRollNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/naecoaching/rankings/${testID}`
        );
        
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        
        const data: Participant[] = await response.json();
        setRankingData(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
    
    // Retrieve participant data from localStorage
    const name = localStorage.getItem("participantName") || "";
    const rollNumber = localStorage.getItem("rollNumber") || "";
    setParticipantName(name);
    setParticipantRollNumber(rollNumber);
  }, []);

  // Find the participant's data
  const participantData = rankingData.find(
    (participant) =>
      participant.participant_name === participantName &&
      participant.roll_number === participantRollNumber
  );

  // Sort the ranking data by total_marks in descending order
  const sortedRankingData = [...rankingData].sort(
    (a, b) => b.total_marks - a.total_marks
  );

  // Function to format time display
  const formatTime = (seconds: number): string => {
    if (seconds < 60) {
      return `${seconds.toFixed(2)}s`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}m ${remainingSeconds.toFixed(0)}s`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${minutes}m`;
    }
  };

  // Find the participant's rank
  const participantRank = participantData
    ? sortedRankingData.findIndex(
        (p) =>
          p.participant_name === participantData.participant_name &&
          p.roll_number === participantData.roll_number
      ) + 1
    : null;

  if (loading) {
    return (
      <div className="text-center mt-6 p-4">
        <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full mx-auto mb-2"></div>
        <p>Loading ranking data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-6 p-4 bg-red-50 text-red-600 rounded-lg">
        {error}
      </div>
    );
  }

  return (
    <div className="p-4 md:p-6 bg-gray-100 min-h-screen">
      <p className='text-red-400 text-center'>Note : these Rankings do not include offline Students' ranks. It will be included before final ranking released. </p>
      <h1 className="text-2xl md:text-3xl font-bold text-center mb-6">Ranking List</h1>
      
      {participantData && (
        <div className="bg-blue-100 p-4 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-3">Your Ranking</h2>
          
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-blue-200">
                  <th className="p-3 text-left font-semibold">Rank</th>
                  <th className="p-3 text-left font-semibold">Name</th>
                  <th className="p-3 text-left font-semibold">Roll Number</th>
                  <th className="p-3 text-left font-semibold">Total Marks</th>
                  <th className="p-3 text-left font-semibold">Total Time</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-blue-50">
                  <td className="p-3 font-bold text-blue-700">#{participantRank}</td>
                  <td className="p-3">{participantData.participant_name}</td>
                  <td className="p-3">{participantData.roll_number}</td>
                  <td className="p-3">{participantData.total_marks}</td>
                  <td className="p-3">{formatTime(participantData.total_time)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div className="mt-4 bg-white p-3 rounded">
            <h3 className="font-medium mb-2">Subject-wise Marks:</h3>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
              <div className="bg-blue-50 p-2 rounded text-center">
                <p className="text-sm text-gray-600">Mathematics</p>
                <p className="font-semibold">{participantData.marks.Mathematics}</p>
              </div>
              <div className="bg-green-50 p-2 rounded text-center">
                <p className="text-sm text-gray-600">Biology</p>
                <p className="font-semibold">{participantData.marks.Biology}</p>
              </div>
              <div className="bg-purple-50 p-2 rounded text-center">
                <p className="text-sm text-gray-600">Chemistry</p>
                <p className="font-semibold">{participantData.marks.Chemistry}</p>
              </div>
              <div className="bg-yellow-50 p-2 rounded text-center">
                <p className="text-sm text-gray-600">Physics</p>
                <p className="font-semibold">{participantData.marks.Physics}</p>
              </div>
              <div className="bg-red-50 p-2 rounded text-center">
                <p className="text-sm text-gray-600">Social Science</p>
                <p className="font-semibold">{participantData.marks["Social Science"]}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <h2 className="text-xl font-semibold p-4 bg-gray-200">Complete Rankings</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-3 text-left font-semibold">Rank</th>
                <th className="p-3 text-left font-semibold">Name</th>
                <th className="p-3 text-left font-semibold">Roll Number</th>
                <th className="p-3 text-left font-semibold">Total Marks</th>
                <th className="p-3 text-left font-semibold">Total Time</th>
              </tr>
            </thead>
            <tbody>
              {sortedRankingData.map((participant, index) => (
                <tr
                  key={participant.roll_number}
                  className={`
                    ${participant.participant_name === participantName &&
                      participant.roll_number === participantRollNumber
                      ? "bg-blue-50"
                      : index % 2 === 0
                      ? "bg-gray-50"
                      : "bg-white"
                    } border-b
                  `}
                >
                  <td className="p-3 font-semibold">#{index + 1}</td>
                  <td className="p-3">{participant.participant_name}</td>
                  <td className="p-3">{participant.roll_number}</td>
                  <td className="p-3">{participant.total_marks}</td>
                  <td className="p-3">{formatTime(participant.total_time)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      <div className="mt-6 text-center text-sm text-gray-500">
        Total Participants: {rankingData.length}
      </div>
    </div>
  );
};

export default RankingList;