import React, { useState, useRef, useEffect } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { useParams } from 'react-router-dom';

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${require('pdfjs-dist/package.json').version}/build/pdf.worker.min.js`;

interface PdfReaderProps {
  defaultFileUrl: string;
}

const PdfReader: React.FC<PdfReaderProps> = ({ defaultFileUrl }) => {
  const { subPath, pdfLink } = useParams<{subPath?: string; pdfLink?: string }>();
  const API_URL = process.env.REACT_APP_API_URL || '';  // Ensure API_URL is correctly set
  
  // Function to determine the correct file URL based on the domain
  const getFileUrl = () => {
    if (!pdfLink) return defaultFileUrl;
    
    // Check if we're running on localhost
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    
    if (isLocalhost) {
      // Use local path for localhost
      return `${API_URL}/media/${subPath}/${pdfLink}.pdf`;
    } else if (window.location.hostname.includes('naecoaching.com')) {
      // For naecoaching.com domain, use the naendapi URL
      return `https://naendapi.naecoaching.com/media/${subPath}/${pdfLink}.pdf`;
    } else {
      // Default fallback
      return `${API_URL}/media/${subPath}/${pdfLink}.pdf`;
    }
  };
  
  const fileUrl = getFileUrl();
  
  const [pdfDoc, setPdfDoc] = useState<any>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pages, setPages] = useState<{ src: string; height: number }[]>([]);
  const [containerHeight, setContainerHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Function to get auth token from localStorage
  const getAuthToken = (): string | null => {
    return localStorage.getItem('token');
  };
  
  useEffect(() => {
    const fetchPdf = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // Create a proxy URL for handling CORS
        let pdfUrl = fileUrl;
        let fetchOptions: RequestInit = {};
        
        // Get auth token from localStorage
        const token = getAuthToken();
        
        // If we're on naecoaching.com and trying to access naendapi.naecoaching.com
        if (
          window.location.hostname.includes('naecoaching.com') && 
          fileUrl.includes('naendapi.naecoaching.com')
        ) {
          // Setup proper fetch options with token
          fetchOptions = { 
            mode: 'cors',  // Changed from 'no-cors' to 'cors'
            credentials: 'include',
            headers: {
              'Accept': 'application/pdf',
              // Include Authorization header if token exists - using "Token" instead of "Bearer"
              ...(token && { 'Authorization': `Token ${token}` })
            }
          };
        } else {
          // For other cases, still include Authorization if token exists
          fetchOptions = {
            headers: {
              'Accept': 'application/pdf',
              ...(token && { 'Authorization': `Token ${token}` })
            }
          };
        }
        
        // Try the fetch with the appropriate options
        const response = await fetch(pdfUrl, fetchOptions);
        
        if (!response.ok) {
          throw new Error(`Failed to fetch the PDF file: ${response.status} ${response.statusText}`);
        }
        
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const pdf = await getDocument({ data: arrayBuffer }).promise;
        setPdfDoc(pdf);
        setTotalPages(pdf.numPages);

        const pageImages: { src: string; height: number }[] = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          if (!context) continue;

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport,
          };

          await page.render(renderContext).promise;
          const imgSrc = canvas.toDataURL();
          pageImages.push({ src: imgSrc, height: viewport.height });
        }
        setPages(pageImages);
        setContainerHeight(pageImages[0]?.height || 0);
      } catch (error: any) {
        console.error('Error loading PDF:', error);
        setError(error.message || 'Failed to load PDF');
        
        // Create a fallback iframe for viewing with token in the URL if available
        if (containerRef.current) {
          const token = getAuthToken();
          let fallbackUrl = fileUrl;
          
          // If token exists, append it as a query parameter for iframe
          // Note: This is less secure than using headers and only a fallback approach
          if (token && fileUrl.indexOf('?') === -1) {
            fallbackUrl = `${fileUrl}?token=${token}`;
          } else if (token) {
            fallbackUrl = `${fileUrl}&token=${token}`;
          }
          
          const fallbackContainer = document.createElement('div');
          fallbackContainer.style.width = '100%';
          fallbackContainer.style.height = '100%';
          fallbackContainer.innerHTML = `
            <div style="padding: 20px; text-align: center;">
              <p>Unable to render PDF directly due to CORS or authorization restrictions.</p>
              <p>You can <a href="${fallbackUrl}" target="_blank">open the PDF in a new tab</a>.</p>
              <iframe 
                src="${fallbackUrl}"
                style="width:100%; height:80vh; border:1px solid #ddd; margin-top: 20px;" 
                title="PDF Document"
              ></iframe>
            </div>
          `;
          
          // Clear the container
          while (containerRef.current.firstChild) {
            containerRef.current.removeChild(containerRef.current.firstChild);
          }
          containerRef.current.appendChild(fallbackContainer);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPdf();
  }, [fileUrl, pdfLink]); // Added pdfLink as dependency

  if (loading) {
    return (
      <div className="pdf-reader p-4 mx-auto max-w-4xl border border-gray-300 rounded-lg shadow-lg bg-white">
        <div className="flex justify-center items-center h-64">
          <p>Loading PDF...</p>
        </div>
      </div>
    );
  }

  // Render the PDF viewer with fallback
  return (
    <div className="pdf-reader p-4 mx-auto max-w-4xl border border-gray-300 rounded-lg shadow-lg bg-white" ref={containerRef}>
      {error ? (
        <div className="flex flex-col justify-center items-center h-64">
          <p className="text-red-500">{error}</p>
          <a 
            href={fileUrl} 
            target="_blank" 
            rel="noopener noreferrer"
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={(e) => {
              // Append token to URL when opening in new tab if available
              const token = getAuthToken();
              if (token) {
                e.preventDefault();
                let newUrl = fileUrl;
                if (newUrl.indexOf('?') === -1) {
                  newUrl = `${newUrl}?token=${token}`;
                } else {
                  newUrl = `${newUrl}&token=${token}`;
                }
                window.open(newUrl, '_blank');
              }
            }}
          >
            Open PDF in New Tab
          </a>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-4 border-b border-gray-200 pb-2">
            <div className="text-gray-700">
              {totalPages > 0 ? `${totalPages} pages` : 'PDF Viewer'}
            </div>
            <div className="space-x-2">
              <p>{pdfLink}</p>
            </div>
          </div>
          {pages.length > 0 ? (
            <div className="relative overflow-y-auto h-screen no-scrollbar">
              <div className="absolute transition-transform duration-500 ease-in-out">
                {pages.map((page, index) => (
                  <div key={index} className="w-full h-full my-2">
                    <img
                      src={page.src}
                      alt={`Page ${index + 1}`}
                      className="w-full h-auto"
                      style={{ maxHeight: '100%' }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="h-screen">
              {/* The iframe will be injected here via the useEffect */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PdfReader;