import React, { useState, useEffect } from 'react';
import { Award, Calendar, Search, Users, ArrowRight } from 'lucide-react';

interface Student {
  SN: number;
  NAME: string;
  ROLLNUMBER: string;
  TOTAL: number;
  MATHS: number;
  SCIENCE: number;
  SSC: number;
}

const ResultsPage: React.FC = () => {
  const [isAnimated, setIsAnimated] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchBy, setSearchBy] = useState<'name' | 'rollnumber'>('name');
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Student;
    direction: 'ascending' | 'descending';
  } | null>(null);
  const [highlightedStudent, setHighlightedStudent] = useState<Student | null>(null);

  // Sample student data
  const studentsData: Student[] = [
    { SN: 1, NAME: "Aman kumar", ROLLNUMBER: "2500050", TOTAL: 82, MATHS: 17, SCIENCE: 50, SSC: 15 },
    { SN: 2, NAME: "Bishu Kumar Yadav", ROLLNUMBER: "2500470", TOTAL: 81, MATHS: 15, SCIENCE: 52, SSC: 14 },
    { SN: 3, NAME: "Md Wasif Raja", ROLLNUMBER: "2500080", TOTAL: 73, MATHS: 14, SCIENCE: 47, SSC: 12 },
    { SN: 4, NAME: "Om Prakash Sinha", ROLLNUMBER: "2500226", TOTAL: 73, MATHS: 18, SCIENCE: 41, SSC: 14 },
    { SN: 5, NAME: "Nigaris Begum", ROLLNUMBER: "2500388", TOTAL: 73, MATHS: 10, SCIENCE: 53, SSC: 10 },
    { SN: 6, NAME: "Md imran Alam", ROLLNUMBER: "2500022", TOTAL: 72, MATHS: 13, SCIENCE: 47, SSC: 12 },
    { SN: 7, NAME: "Khushnama khatun", ROLLNUMBER: "2500087", TOTAL: 72, MATHS: 13, SCIENCE: 46, SSC: 13 },
    { SN: 8, NAME: "ALTAMAS RAZA", ROLLNUMBER: "2500323", TOTAL: 71, MATHS: 13, SCIENCE: 47, SSC: 11 },
    { SN: 9, NAME: "Navnit kumar", ROLLNUMBER: "2500323", TOTAL: 71, MATHS: 13, SCIENCE: 46, SSC: 12 },
    { SN: 10, NAME: "Nafis Alam", ROLLNUMBER: "2500033", TOTAL: 68, MATHS: 10, SCIENCE: 42, SSC: 16 },
    { SN: 11, NAME: "Nafis Alam", ROLLNUMBER: "2500409", TOTAL: 67, MATHS: 9, SCIENCE: 46, SSC: 12 },
    { SN: 12, NAME: "Shah jamal", ROLLNUMBER: "2500446", TOTAL: 67, MATHS: 14, SCIENCE: 39, SSC: 14 },
    { SN: 13, NAME: "Wasim Raja", ROLLNUMBER: "2500041", TOTAL: 67, MATHS: 7, SCIENCE: 51, SSC: 9 },
    { SN: 14, NAME: "Md irfan", ROLLNUMBER: "2500184", TOTAL: 67, MATHS: 9, SCIENCE: 46, SSC: 12 },
    { SN: 15, NAME: "Muskan", ROLLNUMBER: "2500295", TOTAL: 65, MATHS: 13, SCIENCE: 42, SSC: 12 },
    { SN: 16, NAME: "Rifat Naz", ROLLNUMBER: "2500003", TOTAL: 65, MATHS: 9, SCIENCE: 45, SSC: 11 },
    { SN: 17, NAME: "Khushbu", ROLLNUMBER: "2500064", TOTAL: 65, MATHS: 16, SCIENCE: 36, SSC: 11 },
    { SN: 18, NAME: "Mangal Kumar Singh", ROLLNUMBER: "2500397", TOTAL: 64, MATHS: 11, SCIENCE: 46, SSC: 7 },
    { SN: 19, NAME: "Kashis Noori", ROLLNUMBER: "2500051", TOTAL: 64, MATHS: 6, SCIENCE: 45, SSC: 13 },
    { SN: 20, NAME: "Khushbu kumari", ROLLNUMBER: "2500064", TOTAL: 63, MATHS: 16, SCIENCE: 36, SSC: 11 },
    { SN: 21, NAME: "Naaz Parween", ROLLNUMBER: "2500047", TOTAL: 63, MATHS: 9, SCIENCE: 41, SSC: 13 },
    { SN: 22, NAME: "Manzar Alam", ROLLNUMBER: "2500347", TOTAL: 62, MATHS: 13, SCIENCE: 41, SSC: 8 },
    { SN: 23, NAME: "Md Asif", ROLLNUMBER: "2500364", TOTAL: 61, MATHS: 11, SCIENCE: 42, SSC: 8 },
    { SN: 24, NAME: "Barzish Naz", ROLLNUMBER: "2500045", TOTAL: 60, MATHS: 8, SCIENCE: 39, SSC: 13 },
    { SN: 25, NAME: "Tasbeer", ROLLNUMBER: "2500020", TOTAL: 60, MATHS: 7, SCIENCE: 38, SSC: 15 },
    { SN: 26, NAME: "Md Inayat ali", ROLLNUMBER: "2500041", TOTAL: 59, MATHS: 9, SCIENCE: 40, SSC: 10 },
    { SN: 27, NAME: "Rahim alam", ROLLNUMBER: "2500234", TOTAL: 59, MATHS: 10, SCIENCE: 40, SSC: 9 },
    { SN: 28, NAME: "Masuri begam", ROLLNUMBER: "2500053", TOTAL: 58, MATHS: 10, SCIENCE: 34, SSC: 14 },
    { SN: 29, NAME: "shrimayi ray", ROLLNUMBER: "2500057", TOTAL: 57, MATHS: 11, SCIENCE: 38, SSC: 8 },
    { SN: 30, NAME: "Khalida gehum", ROLLNUMBER: "2500077", TOTAL: 57, MATHS: 9, SCIENCE: 40, SSC: 8 },
    { SN: 31, NAME: "Madeena afreen", ROLLNUMBER: "2500370", TOTAL: 56, MATHS: 9, SCIENCE: 38, SSC: 9 },
    { SN: 32, NAME: "Preety begam", ROLLNUMBER: "2500014", TOTAL: 56, MATHS: 4, SCIENCE: 42, SSC: 10 },
    { SN: 33, NAME: "Siva begum", ROLLNUMBER: "2500004", TOTAL: 55, MATHS: 11, SCIENCE: 33, SSC: 11 },
    { SN: 34, NAME: "Farhin naz", ROLLNUMBER: "2500296", TOTAL: 54, MATHS: 9, SCIENCE: 34, SSC: 11 },
    { SN: 35, NAME: "Shama parveen", ROLLNUMBER: "2500040", TOTAL: 54, MATHS: 11, SCIENCE: 35, SSC: 8 },
    { SN: 36, NAME: "Md saddam", ROLLNUMBER: "2500048", TOTAL: 53, MATHS: 6, SCIENCE: 39, SSC: 8 },
    { SN: 37, NAME: "Shimam reza", ROLLNUMBER: "2500034", TOTAL: 52, MATHS: 7, SCIENCE: 36, SSC: 9 },
    { SN: 38, NAME: "Zeba saltanat", ROLLNUMBER: "2500042", TOTAL: 51, MATHS: 8, SCIENCE: 37, SSC: 6 },
    { SN: 39, NAME: "Sahil", ROLLNUMBER: "2500298", TOTAL: 51, MATHS: 5, SCIENCE: 37, SSC: 9 },
    { SN: 40, NAME: "NASREEN JAHN", ROLLNUMBER: "2500301", TOTAL: 49, MATHS: 7, SCIENCE: 29, SSC: 13 },
    { SN: 41, NAME: "NAFIS RAZA", ROLLNUMBER: "2500301", TOTAL: 47, MATHS: 6, SCIENCE: 31, SSC: 10 },
    { SN: 42, NAME: "CHANDAN KUMAR SINGH", ROLLNUMBER: "2500252", TOTAL: 47, MATHS: 7, SCIENCE: 29, SSC: 11 },
    { SN: 43, NAME: "NEHA PARVEEN", ROLLNUMBER: "2500089", TOTAL: 47, MATHS: 4, SCIENCE: 28, SSC: 14 },
    { SN: 44, NAME: "NEHA PARWEEN", ROLLNUMBER: "2500089", TOTAL: 47, MATHS: 4, SCIENCE: 29, SSC: 14 },
    { SN: 45, NAME: "AZMAT JAHA", ROLLNUMBER: "2500005", TOTAL: 46, MATHS: 6, SCIENCE: 27, SSC: 13 },
    { SN: 46, NAME: "SHABNAM AARA", ROLLNUMBER: "2500018", TOTAL: 45, MATHS: 6, SCIENCE: 2, SSC: 11 },
    { SN: 47, NAME: "NIHAR PARWEEN", ROLLNUMBER: "2500005", TOTAL: 45, MATHS: 9, SCIENCE: 30, SSC: 6 },
    { SN: 48, NAME: "GOSHIYA NAAZ", ROLLNUMBER: "2500050", TOTAL: 45, MATHS: 12, SCIENCE: 26, SSC: 7 },
    { SN: 49, NAME: "MUKARRAM REZA", ROLLNUMBER: "2500036", TOTAL: 44, MATHS: 13, SCIENCE: 21, SSC: 10 },
    { SN: 50, NAME: "ROSHNI", ROLLNUMBER: "2500277", TOTAL: 44, MATHS: 7, SCIENCE: 31, SSC: 6 },
    { SN: 51, NAME: "ROSHNI TAMANNA", ROLLNUMBER: "2500277", TOTAL: 44, MATHS: 7, SCIENCE: 31, SSC: 6 },
    { SN: 52, NAME: "SHAISTA BEGAM", ROLLNUMBER: "2500144", TOTAL: 43, MATHS: 10, SCIENCE: 25, SSC: 8 },
    { SN: 53, NAME: "AFRIN BEGAM", ROLLNUMBER: "2500006", TOTAL: 41, MATHS: 10, SCIENCE: 23, SSC: 8 },
    { SN: 54, NAME: "PREMA KUMARI", ROLLNUMBER: "2500174", TOTAL: 40, MATHS: 7, SCIENCE: 22, SSC: 11 },
    { SN: 55, NAME: "ILTAF", ROLLNUMBER: "2500001", TOTAL: 40, MATHS: 6, SCIENCE: 25, SSC: 9 },
    { SN: 56, NAME: "SUSHMA SOREN", ROLLNUMBER: "2500347", TOTAL: 39, MATHS: 6, SCIENCE: 22, SSC: 11 },
    { SN: 57, NAME: "Sushma", ROLLNUMBER: "2500347", TOTAL: 39, MATHS: 6, SCIENCE: 22, SSC: 11 },
    { SN: 58, NAME: "Riya begam", ROLLNUMBER: "2500091", TOTAL: 39, MATHS: 6, SCIENCE: 24, SSC: 9 },
    { SN: 59, NAME: "Bilkesh jahan", ROLLNUMBER: "2500143", TOTAL: 39, MATHS: 5, SCIENCE: 25, SSC: 9 },
    { SN: 60, NAME: "Khusboo jahan", ROLLNUMBER: "2500076", TOTAL: 39, MATHS: 6, SCIENCE: 23, SSC: 10 },
    { SN: 61, NAME: "Imran sekh", ROLLNUMBER: "2500344", TOTAL: 39, MATHS: 9, SCIENCE: 19, SSC: 11 },
    { SN: 62, NAME: "TAMANNA", ROLLNUMBER: "2500021", TOTAL: 38, MATHS: 3, SCIENCE: 25, SSC: 10 },
    { SN: 63, NAME: "Bushra fatma", ROLLNUMBER: "2500154", TOTAL: 38, MATHS: 7, SCIENCE: 25, SSC: 6 },
    { SN: 64, NAME: "RAHAT PARWEEN", ROLLNUMBER: "2500011", TOTAL: 37, MATHS: 3, SCIENCE: 31, SSC: 3 },
    { SN: 65, NAME: "Rahat", ROLLNUMBER: "2500011", TOTAL: 37, MATHS: 3, SCIENCE: 31, SSC: 3 },
    { SN: 66, NAME: "Neha parween", ROLLNUMBER: "2500596", TOTAL: 36, MATHS: 1, SCIENCE: 26, SSC: 9 },
    { SN: 67, NAME: "Falak nazra", ROLLNUMBER: "2500121", TOTAL: 35, MATHS: 6, SCIENCE: 21, SSC: 8 },
    { SN: 68, NAME: "SHAMA PARWEEN", ROLLNUMBER: "2500013", TOTAL: 34, MATHS: 5, SCIENCE: 20, SSC: 9 },
    { SN: 69, NAME: "SAHNILA PARWEEN", ROLLNUMBER: "2500056", TOTAL: 34, MATHS: 4, SCIENCE: 26, SSC: 4 },
    { SN: 70, NAME: "Mahak fatma", ROLLNUMBER: "2500074", TOTAL: 34, MATHS: 9, SCIENCE: 19, SSC: 7 },
    { SN: 71, NAME: "KHUSHBU BEGUM", ROLLNUMBER: "2500038", TOTAL: 33, MATHS: 7, SCIENCE: 18, SSC: 8 },
    { SN: 72, NAME: "KAMRAN RAIS", ROLLNUMBER: "2500223", TOTAL: 33, MATHS: 5, SCIENCE: 17, SSC: 11 },
    { SN: 73, NAME: "Md Ariyan rahi", ROLLNUMBER: "2500042", TOTAL: 33, MATHS: 5, SCIENCE: 22, SSC: 6 },
    { SN: 74, NAME: "RISNIKESH SRIWASTAV", ROLLNUMBER: "2500236", TOTAL: 32, MATHS: 2, SCIENCE: 21, SSC: 9 },
    { SN: 75, NAME: "Ravita Kumari", ROLLNUMBER: "2500257", TOTAL: 32, MATHS: 10, SCIENCE: 16, SSC: 6 },
    { SN: 76, NAME: "Md Aariyan rahi", ROLLNUMBER: "2500042", TOTAL: 32, MATHS: 5, SCIENCE: 21, SSC: 6 },
    { SN: 77, NAME: "TARANNUM PINKI", ROLLNUMBER: "2500061", TOTAL: 31, MATHS: 2, SCIENCE: 17, SSC: 12 },
    { SN: 78, NAME: "MANSI KUMARI", ROLLNUMBER: "2500187", TOTAL: 31, MATHS: 6, SCIENCE: 21, SSC: 4 },
    { SN: 79, NAME: "Gajala praween", ROLLNUMBER: "2500214", TOTAL: 31, MATHS: 3, SCIENCE: 21, SSC: 7 },
    { SN: 80, NAME: "Mansi Kumari", ROLLNUMBER: "2500187", TOTAL: 31, MATHS: 6, SCIENCE: 21, SSC: 4 },
    { SN: 81, NAME: "Tarannum pinki", ROLLNUMBER: "2500061", TOTAL: 31, MATHS: 2, SCIENCE: 17, SSC: 12 },
    { SN: 82, NAME: "KHUSHBU BEGUM", ROLLNUMBER: "2500126", TOTAL: 30, MATHS: 4, SCIENCE: 22, SSC: 4 },
    { SN: 83, NAME: "MANSI BEGAM", ROLLNUMBER: "2500035", TOTAL: 30, MATHS: 10, SCIENCE: 14, SSC: 6 },
    { SN: 84, NAME: "Mansi", ROLLNUMBER: "2500035", TOTAL: 30, MATHS: 6, SCIENCE: 14, SSC: 10 },
    { SN: 85, NAME: "Reshmi begum", ROLLNUMBER: "2500028", TOTAL: 30, MATHS: 4, SCIENCE: 16, SSC: 10 },
    { SN: 86, NAME: "Roshni begam", ROLLNUMBER: "2500105", TOTAL: 29, MATHS: 5, SCIENCE: 18, SSC: 6 },
    { SN: 87, NAME: "Seema khatun", ROLLNUMBER: "2500215", TOTAL: 29, MATHS: 2, SCIENCE: 24, SSC: 3 },
    { SN: 88, NAME: "Shayar parwaz", ROLLNUMBER: "2500031", TOTAL: 29, MATHS: 6, SCIENCE: 18, SSC: 5 },
    { SN: 89, NAME: "Neha begum", ROLLNUMBER: "2500020", TOTAL: 28, MATHS: 6, SCIENCE: 18, SSC: 4 },
    { SN: 90, NAME: "Suman kumar singh", ROLLNUMBER: "2500237", TOTAL: 28, MATHS: 6, SCIENCE: 15, SSC: 7 },
    { SN: 91, NAME: "TEJALLI", ROLLNUMBER: "2500324", TOTAL: 27, MATHS: 4, SCIENCE: 14, SSC: 9 },
    { SN: 92, NAME: "NADEEM AKHTER", ROLLNUMBER: "2500012", TOTAL: 26, MATHS: 7, SCIENCE: 14, SSC: 5 },
    { SN: 93, NAME: "Rahat parween", ROLLNUMBER: "2500030", TOTAL: 26, MATHS: 4, SCIENCE: 16, SSC: 6 },
    { SN: 94, NAME: "Nadeem akhter", ROLLNUMBER: "2500012", TOTAL: 26, MATHS: 7, SCIENCE: 14, SSC: 5 },
    { SN: 95, NAME: "Tajalli", ROLLNUMBER: "2500324", TOTAL: 26, MATHS: 3, SCIENCE: 14, SSC: 9 },
    { SN: 96, NAME: "MD ASIF", ROLLNUMBER: "2500506", TOTAL: 25, MATHS: 5, SCIENCE: 12, SSC: 8 },
    { SN: 97, NAME: "Fairany begun", ROLLNUMBER: "2500027", TOTAL: 25, MATHS: 3, SCIENCE: 16, SSC: 6 },
    { SN: 98, NAME: "Santoshi", ROLLNUMBER: "2500178", TOTAL: 24, MATHS: 4, SCIENCE: 14, SSC: 6 },
    { SN: 99, NAME: "Gunnaz begum", ROLLNUMBER: "2500016", TOTAL: 23, MATHS: 6, SCIENCE: 15, SSC: 2 },
    { SN: 100, NAME: "MD KAMRAN HASMI", ROLLNUMBER: "2500481", TOTAL: 22, MATHS: 2, SCIENCE: 17, SSC: 3 },
    { SN: 101, NAME: "Sweety begum", ROLLNUMBER: "2500008", TOTAL: 22, MATHS: 3, SCIENCE: 13, SSC: 6 },
    { SN: 102, NAME: "Mosmin", ROLLNUMBER: "2500509", TOTAL: 21, MATHS: 5, SCIENCE: 13, SSC: 3 },
    { SN: 103, NAME: "Sangita kumari", ROLLNUMBER: "2500258", TOTAL: 19, MATHS: 8, SCIENCE: 15, SSC: 6 }
  ]

  // Function to sort data
  const sortData = (key: keyof Student, direction: 'ascending' | 'descending') => {
    const sorted = [...filteredStudents].sort((a, b) => {
      if (typeof a[key] === 'string' && typeof b[key] === 'string') {
        const aValue = (a[key] as string).toLowerCase();
        const bValue = (b[key] as string).toLowerCase();
        if (direction === 'ascending') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      } else {
        if (direction === 'ascending') {
          return (a[key] as number) - (b[key] as number);
        } else {
          return (b[key] as number) - (a[key] as number);
        }
      }
    });
    setFilteredStudents(sorted);
  };

  // Handle sorting when column header is clicked
  const handleSort = (key: keyof Student) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    
    setSortConfig({ key, direction });
    sortData(key, direction);
  };

  // Calculate grade based on TOTAL
  const calculateGrade = (total: number): string => {
    if (total >= 80) return 'A+';
    if (total >= 70) return 'A';
    if (total >= 60) return 'B+';
    if (total >= 50) return 'B';
    if (total >= 40) return 'C';
    if (total >= 15) return 'D';
    return 'F';
  };

  // Function to get color class based on grade
  const getGradeColor = (grade: string): string => {
    switch (grade) {
      case 'A+': return 'bg-green-100 text-green-800';
      case 'A': return 'bg-green-50 text-green-700';
      case 'B+': return 'bg-blue-100 text-blue-800';
      case 'B': return 'bg-blue-50 text-blue-700';
      case 'C': return 'bg-yellow-100 text-yellow-800';
      case 'D': return 'bg-orange-100 text-orange-800';
      case 'F': return 'bg-red-100 text-red-800';
      default: return '';
    }
  };

  // Initialize filtered students with all students
  useEffect(() => {
    setIsAnimated(true);
    setFilteredStudents(studentsData);
  }, []);

  

  // Filter students based on search query
  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredStudents(studentsData);
      setHighlightedStudent(null);
      return;
    }

    const query = searchQuery.toLowerCase();
    const filtered = studentsData.filter(student => {
      if (searchBy === 'name') {
        return student.NAME.toLowerCase().includes(query);
      } else {
        return student.ROLLNUMBER.includes(query);
      }
    });

    setFilteredStudents(filtered);

    // If there's an exact match, highlight it
    if (filtered.length === 1) {
      setHighlightedStudent(filtered[0]);
    } else {
      setHighlightedStudent(null);
    }
  }, [searchQuery, searchBy]);

  // Apply sorting whenever sortConfig changes
  useEffect(() => {
    if (sortConfig) {
      sortData(sortConfig.key, sortConfig.direction);
    }
  }, [sortConfig]);

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">N A E Talent Test Results</h1>
          <p className="text-gray-600">Academic Year 2024-2025</p>
        </div>

        {/* Search Section */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="flex-1">
              <label htmlFor="search" className="block text-sm font-medium text-gray-700 mb-1">
                Search your result
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300 p-2 border"
                  placeholder={searchBy === 'name' ? "Enter student name..." : "Enter roll number..."}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full sm:w-auto">
              <label htmlFor="searchBy" className="block text-sm font-medium text-gray-700 mb-1">
                Search by
              </label>
              <select
                id="searchBy"
                name="searchBy"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value as 'name' | 'rollnumber')}
              >
                <option value="name">Name</option>
                <option value="rollnumber">Roll Number</option>
              </select>
            </div>
          </div>

          {/* Highlighted search result */}
          {highlightedStudent && (
            <div className="mt-6 p-4 border border-indigo-200 rounded-lg bg-indigo-50">
              <h3 className="text-lg font-medium text-indigo-800">Student Details</h3>
              <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="p-3 bg-white rounded shadow-sm">
                  <p className="text-sm text-gray-500">Name</p>
                  <p className="font-semibold">{highlightedStudent.NAME}</p>
                </div>
                <div className="p-3 bg-white rounded shadow-sm">
                  <p className="text-sm text-gray-500">Roll Number</p>
                  <p className="font-semibold">{highlightedStudent.ROLLNUMBER}</p>
                </div>
                <div className="p-3 bg-white rounded shadow-sm">
                  <p className="text-sm text-gray-500">Total Score</p>
                  <p className="font-semibold">{highlightedStudent.TOTAL}/100</p>
                </div>
              </div>
              
              <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="p-3 bg-white rounded shadow-sm">
                  <p className="text-sm text-gray-500">Mathematics</p>
                  <p className="font-semibold">{highlightedStudent.MATHS}/20</p>
                </div>
                <div className="p-3 bg-white rounded shadow-sm">
                  <p className="text-sm text-gray-500">Science</p>
                  <p className="font-semibold">{highlightedStudent.SCIENCE}/60</p>
                </div>
                <div className="p-3 bg-white rounded shadow-sm">
                  <p className="text-sm text-gray-500">SSC</p>
                  <p className="font-semibold">{highlightedStudent.SSC}/20</p>
                </div>
              </div>
              
              <div className="mt-4 flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <span className="text-gray-700">Final Grade:</span> 
                  <span className={`${getGradeColor(calculateGrade(highlightedStudent.TOTAL))} px-2 py-1 rounded-full text-xs font-medium`}>
                    {calculateGrade(highlightedStudent.TOTAL)}
                  </span>
                </div>
                <div className="text-right">
                  <p className="text-sm text-gray-500">Rank</p>
                  <p className="font-bold text-lg">{highlightedStudent.SN} of 103</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Results Table */}
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th 
                    scope="col" 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('SN')}
                  >
                    Rank
                    {sortConfig?.key === 'SN' && (
                      <span className="ml-1">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th 
                    scope="col" 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('NAME')}
                  >
                    Name
                    {sortConfig?.key === 'NAME' && (
                      <span className="ml-1">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th 
                    scope="col" 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('ROLLNUMBER')}
                  >
                    Roll No.
                    {sortConfig?.key === 'ROLLNUMBER' && (
                      <span className="ml-1">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th 
                    scope="col" 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('TOTAL')}
                  >
                    Total
                    {sortConfig?.key === 'TOTAL' && (
                      <span className="ml-1">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th 
                    scope="col" 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('MATHS')}
                  >
                    Maths
                    {sortConfig?.key === 'MATHS' && (
                      <span className="ml-1">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th 
                    scope="col" 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('SCIENCE')}
                  >
                    Science
                    {sortConfig?.key === 'SCIENCE' && (
                      <span className="ml-1">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th 
                    scope="col" 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort('SSC')}
                  >
                    SSC
                    {sortConfig?.key === 'SSC' && (
                      <span className="ml-1">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Grade
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredStudents.map((student, index) => (
                  <tr key={`${student.ROLLNUMBER}-${index}`} 
                      className={highlightedStudent && highlightedStudent.ROLLNUMBER === student.ROLLNUMBER ? 'bg-indigo-50' : (index % 2 === 0 ? 'bg-white' : 'bg-gray-50')}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {student.SN}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {student.NAME}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {student.ROLLNUMBER}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      {student.TOTAL}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {student.MATHS}/20
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {student.SCIENCE}/60
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {student.SSC}/20
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getGradeColor(calculateGrade(student.TOTAL))}`}>
                        {calculateGrade(student.TOTAL)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {filteredStudents.length === 0 && (
            <div className="px-6 py-10 text-center text-gray-500">
              No results found. Please try a different search term.
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="mt-8 text-center text-sm text-gray-500">
          <p>For any discrepancies in the results, please contact the examination department.</p>
        </div>
        

        
      </div>
      {/* Stats Section */}
      <div className="my-4 bg-gradient-to-b from-blue-50 to-indigo-100">
      <div className="py-4 bg-white">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className={`bg-blue-50 rounded-lg p-6 shadow-md transition-all duration-700 transform ${isAnimated ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`} style={{ transitionDelay: '200ms' }}>
                <div className="flex items-center mb-4">
                  <Users className="text-blue-600 mr-3" size={24} />
                  <h3 className="text-xl font-semibold text-gray-800">Participants</h3>
                </div>
                <p className="text-4xl font-bold text-blue-600 mb-2">500+</p>
                <p className="text-gray-600">Students participated from across the region</p>
              </div>
              
              <div className={`bg-indigo-50 rounded-lg p-6 shadow-md transition-all duration-700 transform ${isAnimated ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`} style={{ transitionDelay: '400ms' }}>
                <div className="flex items-center mb-4">
                  <Calendar className="text-indigo-600 mr-3" size={24} />
                  <h3 className="text-xl font-semibold text-gray-800">Test Date</h3>
                </div>
                <p className="text-4xl font-bold text-indigo-600 mb-2">Feb 27</p>
                <p className="text-gray-600">Successfully conducted at N A E COACHING center near Railway Gate, Thakurganj</p>
              </div>
              
              <div className={`bg-purple-50 rounded-lg p-6 shadow-md transition-all duration-700 transform ${isAnimated ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`} style={{ transitionDelay: '600ms' }}>
                <div className="flex items-center mb-4">
                  <Search className="text-purple-600 mr-3" size={24} />
                  <h3 className="text-xl font-semibold text-gray-800">Results</h3>
                </div>
                <p className="text-4xl font-bold text-purple-600 mb-2">103</p>
                <p className="text-gray-600">Top rankings published, more coming soon</p>
              </div>
            </div>
          </div>
      </div>
        </div>
    </div>
  );
};

export default ResultsPage;