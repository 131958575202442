import React, { useState } from 'react';

const ClearDataButton = () => {
  const [isClearing, setIsClearing] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  
  const handleClearData = () => {
    setIsClearing(true);
    
    // Clear all specified items from localStorage
    localStorage.removeItem('testData');
    localStorage.removeItem('responseData');
    localStorage.removeItem('subjects');
    localStorage.removeItem('hasStarted');
    localStorage.removeItem('startTime');
    
    // Simulate a short delay to show loading state
    setTimeout(() => {
      setIsClearing(false);
      setIsCleared(true);
      
      // Reset the success message after 3 seconds
      setTimeout(() => {
        setIsCleared(false);
      }, 3000);
    }, 500);
  };

  return (
    <div className="flex flex-col items-center">
      <button
        onClick={handleClearData}
        disabled={isClearing}
        className={`px-4 py-2 rounded-md font-medium transition-colors ${
          isClearing 
            ? 'bg-gray-400 text-gray-100 cursor-not-allowed' 
            : 'bg-red-500 hover:bg-red-600 text-white'
        }`}
      >
        {isClearing ? (
          <span className="flex items-center">
            <svg className="animate-spin h-4 w-4 mr-2" viewBox="0 0 24 24">
              <circle 
                className="opacity-25" 
                cx="12" 
                cy="12" 
                r="10" 
                stroke="currentColor" 
                strokeWidth="4" 
                fill="none" 
              />
              <path 
                className="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" 
              />
            </svg>
            Clearing...
          </span>
        ) : (
          'Clear Storage Data'
        )}
      </button>
      
      {isCleared && (
        <div className="mt-2 text-sm text-green-600 bg-green-100 px-3 py-1 rounded-md">
          Data cleared successfully!
        </div>
      )}
    </div>
  );
};

export default ClearDataButton;