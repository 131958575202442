import React, { useState, useEffect } from 'react';
import { Clock, AlertCircle, ChevronLeft, ChevronRight, BookmarkIcon } from 'lucide-react';
import AttemptAnalysis from './AttemptAnalysis';
import Cookies from 'js-cookie';
import { json } from 'stream/consumers';

type Subject = {
  total: number;
  answered: number;
};

interface Subjects {
  [subject: string]: { total: number; answered: number };
}

type Answers = {
  [key: string]: { [key: string]: number };
};

type MarkedForReview = {
  [key: string]: number[];
};

const API_URL = process.env.REACT_APP_API_URL; // Adjust to your backend URL


const STORAGE_KEYS = {
  testData: 'testData',
  responseData: 'responseData',
  currentQuestion: 'currentQuestion',
  markedForReview: 'markedForReview',
  startTime: 'startTime',
  participantName: 'participantName',
  rollNumber: 'rollNumber',
  subjects: 'subjects',
  answers: 'answers',
  answersWithQid: 'answersWithQid',
  testSubmitted: 'testSubmitted',
  currentSubject: 'currentSubject',
  hasStarted: 'hasStarted',
  testId: 'testId',
};

const TestTakingUI: React.FC = () => {
  // State initialization with localStorage fallback
  const [testData, setTestData] = useState<any>(() => {
    const savedd = localStorage.getItem(STORAGE_KEYS.testData);
    return savedd ? JSON.parse(savedd) : null;
  });
  const [responseData, setResponseData] = useState<any>(() => {
    const savedResponseData = localStorage.getItem(STORAGE_KEYS.responseData);
    return savedResponseData ? JSON.parse(savedResponseData) : null;
  });
  const [timer, setTimer] = useState<number>(0);
  const [testId, setTestId] = useState<number>(0);
  const [hasStarted, setHasStarted] = useState<boolean>(() => {
    return localStorage.getItem(STORAGE_KEYS.hasStarted) === 'true';
  });
  const [startTime, setStartTime] = useState<number | null>(() => {
    const stored = localStorage.getItem(STORAGE_KEYS.startTime);
    return stored ? Number(stored) : null;
  });
  const [participantName, setParticipantName] = useState<string>(() => {
    return localStorage.getItem(STORAGE_KEYS.participantName) || '';
  });
  const [rollNumber, setRollNumber] = useState<string>(() => {
    return localStorage.getItem(STORAGE_KEYS.rollNumber) || '';
  });
  const [step, setStep] = useState<number>(1);
  const [currentQuestion, setCurrentQuestion] = useState<number>(() => {
    return parseInt(localStorage.getItem(STORAGE_KEYS.currentQuestion) || '1');
  });
  const [testSubmitted, setTestSubmitted] = useState<boolean>(() => {
    return localStorage.getItem(STORAGE_KEYS.testSubmitted) === 'true';
  });
  const [answers, setAnswers] = useState<Answers>(() => {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.answers) || '{}');
  });
  const [answersWithQid, setAnswersWithQid] = useState<Answers>(() => {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.answersWithQid) || '{}');
  });
  const [markedForReview, setMarkedForReview] = useState<MarkedForReview>(() => {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.markedForReview) || '{}');
  });
  
  const [showSubmitView, setShowSubmitView] = useState<boolean>(false);
  const [subjects, setSubjects] = useState<Subjects>(() => {
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.subjects) || '{}');
  });
  const [currentSubject, setCurrentSubject] = useState<string>(() => {
    return localStorage.getItem(STORAGE_KEYS.currentSubject) || '';
  });

  // Fetch test data and initialize subjects

  useEffect(() => {
    const storedTestData = localStorage.getItem(STORAGE_KEYS.testData);
    const start = localStorage.getItem('startTime')
    // console.log(storedTestData);
    
    
    if(start){
      setStartTime(start ? Number(start): null)
    }
    if (storedTestData) {
      // console.log('data is stored');
      
     const data = JSON.parse(storedTestData);
     if (data) {
       setTestData(data);
       setTestId(data.id);
       const newSubjects: Subjects = {};
       data.subjects.forEach((subject: any) => {
         newSubjects[subject.name] = {
           total: subject.questions.length,
           answered: 0,
         };
       });
       setSubjects(newSubjects);
       localStorage.setItem(STORAGE_KEYS.subjects, JSON.stringify(newSubjects));

       if (!currentSubject && data.subjects.length > 0) {
         setCurrentSubject(data.subjects[0].name);
         localStorage.setItem(STORAGE_KEYS.currentSubject, data.subjects[0].name);
       }

       const savedTime = localStorage.getItem(`ntt ${data.id} time`);
       if (data.duration_minutes) {
         if (hasStarted && startTime) {
           const totalTime = data.duration_minutes * 60;
           const elapsed = Math.floor((Date.now() - startTime) / 1000);
           setTimer(totalTime - elapsed);
         }
        //  localStorage.setItem(`ntt ${data.id} start time`, JSON.stringify(startTime));
       }
     }
    } else {
      fetch(`${API_URL}/naecoaching/tests/4`)
        .then((response) => response.json())
        .then((data) => {
         if (data) {
           setTestData(data);
           setTestId(data.id);
           localStorage.setItem('testData', JSON.stringify(data));
           if (data.subjects && data.subjects.length > 0) {
             setCurrentSubject(data.subjects[0].name);
             setTestId(data.id);
           }
          const newSubjects: Subjects = {};
          data.subjects.forEach((subject: any) => {
            newSubjects[subject.name] = {
              total: subject.questions.length,
              answered: 0,
            };
          });
          setSubjects(newSubjects);
          localStorage.setItem(STORAGE_KEYS.subjects, JSON.stringify(newSubjects));

          if (!currentSubject && data.subjects.length > 0) {
            setCurrentSubject(data.subjects[0].name);
            localStorage.setItem(STORAGE_KEYS.currentSubject, data.subjects[0].name);
          }
          //  if (data.duration_minutes) {
          //    if (hasStarted && startTime) {
          //      const totalTime = data.duration_minutes * 60;
          //      const elapsed = Math.floor((Date.now() - startTime) / 1000);
          //      setTimer(totalTime - elapsed);
          //    }
          //   //  localStorage.setItem(`ntt ${data.id} start time`, JSON.stringify(startTime));
          //  }
         }
       })
       .catch((err) => console.error('Error fetching test data:', err));
   }
        
  }, [hasStarted, step]);
 
  useEffect(() => {
    if (hasStarted && startTime && testData) {
      const totalTime = testData.duration_minutes * 60;
      const interval = setInterval(() => {
        const elapsed = Math.floor((Date.now() - startTime) / 1000);
        const newTimer = totalTime - elapsed;
        if (newTimer <= 0) {
          setTimer(0);
          if (!testSubmitted) {
            handleSubmit();
          }
          clearInterval(interval);
        } else {
          setTimer(newTimer);
          // console.log(timer);
          
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [hasStarted, startTime, testData]);

  // Persist states to localStorage
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.answers, JSON.stringify(answers));
  }, [answers]);
  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.testId, JSON.stringify(testId));
  }, [testId]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.markedForReview, JSON.stringify(markedForReview));
  }, [markedForReview]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.hasStarted, JSON.stringify(hasStarted));
  }, [hasStarted]);

  // useEffect(() => {
  //   localStorage.setItem(STORAGE_KEYS.startTime, JSON.stringify(startTime));
  //   console.log(startTime);
    
  // }, [startTime]);

  

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.participantName, participantName);
  }, [participantName]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.rollNumber, rollNumber);
  }, [rollNumber]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.subjects, JSON.stringify(subjects));
  }, [subjects]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.testSubmitted, JSON.stringify(testSubmitted));
  }, [testSubmitted]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.answersWithQid, JSON.stringify(answersWithQid));
  }, [answersWithQid]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.currentQuestion, currentQuestion.toString());
  }, [currentQuestion]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.currentSubject, currentSubject);
  }, [currentSubject]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.testData, JSON.stringify(testData));
  }, [testData]);

  // Helper functions
  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const getProgressWidth = (subject: string): string => {
    const answeredCount = Object.keys(answers[subject] || {}).length;
    const totalQuestions = subjects[subject]?.total || 1;
    return `${(answeredCount / totalQuestions) * 100}%`;
  };

  const handleAnswering = (subject: string, questionIndex: number, questionId: number, optionIndex: number) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [subject]: {
        ...prevAnswers[subject],
        [questionIndex]: optionIndex + 1,
      },
    }));
    setAnswersWithQid((prevAnswers) => ({
      ...prevAnswers,
      [subject]: {
        ...prevAnswers[subject],
        [questionId]: optionIndex,
      },
    }));
  };

  const toggleMarkForReview = (subject: string, question: number) => {
    setMarkedForReview((prev) => {
      const subjectMarks = prev[subject] || [];
      if (subjectMarks.includes(question)) {
        return {
          ...prev,
          [subject]: subjectMarks.filter((q) => q !== question),
        };
      } else {
        return {
          ...prev,
          [subject]: [...subjectMarks, question],
        };
      }
    });
  };

  const handleNextQuestion = () => {
    if (currentQuestion < subjects[currentSubject].total) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      const subjectsArray = Object.keys(subjects);
      const currentIndex = subjectsArray.indexOf(currentSubject);
      const nextSubjectIndex = (currentIndex + 1) % subjectsArray.length;
      setCurrentSubject(subjectsArray[nextSubjectIndex]);
      setCurrentQuestion(1);
    }
  };

  const submitTest = () => {
    // Retrieve participant data from localStorage
    const participantDataStr = localStorage.getItem('participantData');
    const participantData = participantDataStr ? JSON.parse(participantDataStr) : {};
  
    const submissionBody = {
      ...participantData,
      test: testId, // testId set when fetching d
      start_time:  startTime ? new Date(startTime).toISOString() : null,
      answers: answersWithQid, // Ensure structure matches API expectations
      submitted_at: new Date().toISOString(),
      device: Cookies.get('device_uuid')
    };

    console.log(submissionBody);
  
    fetch(`${API_URL}/naecoaching/submissions/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(submissionBody)
    })
      .then(response => response.json())
      .then(data => {
        // Check if the response has all expected keys:
      if (
        !('id' in data) ||
        !('participant_name' in data) ||
        !('roll_number' in data) ||
        !('start_time' in data) ||
        !('submitted_at' in data) ||
        !('answers' in data) ||
        !('marks' in data) ||
        !('total_time' in data) ||
        !('test' in data) ||
        !('device' in data)
      ) {
        alert(
          "Submission error: Invalid response format.\n" +
          "सबमिशन त्रुटि: अमान्य प्रतिक्रिया स्वरूप।\n\n" +
          JSON.stringify(data, null, 2)
        );
        return;
      }
      setResponseData(data);
      
      localStorage.setItem(STORAGE_KEYS.responseData, JSON.stringify(data));
      console.log("Submission successful:", data);

      alert(
        "Test submitted successfully.\n" +
        "टेस्ट सफलतापूर्वक सबमिट हो गया है।"
      );
      // Optionally clear localStorage or navigate away.
    })
    .catch(error => {
      console.error("Error submitting test:", error);
      alert("Error submitting test.\nटेस्ट सबमिट करते समय त्रुटि हुई।");
    });
  };
  

  const handleSubmit = () => {
    if (
      window.confirm(
        "आप टेस्ट सबमिट कर रहे हैं \n You are submitting the test. You can't solve any question further. Confirm submit?"
      )
    ) {
      alert("सबमिट किया जा रहा है \n Submitting...");
      submitTest();
      setTestSubmitted(true);
    }
  };

  
  // New handler to save details and reset test-related states
  const handleSaveDetails = () => {
    if (!participantName || !rollNumber) {
      alert("कृपया अपना नाम और रोल नंबर दोनों दर्ज करें / Please enter both your name and roll number.");
      return;
    }
    // Reset test-related states
    setAnswers({});
    setAnswersWithQid({});
    setCurrentQuestion(1);
    // Ensure 'subjects' is defined; if not, you might retrieve it from d
    // if (testData && testData.subjects && testData.subjects.length > 0) {
    //   setCurrentSubject(testData.subjects[0].name);
    // }
    setTestSubmitted(false);
    setTestData(null);
    localStorage.removeItem(STORAGE_KEYS.testData)
    setResponseData(null);
    localStorage.removeItem(STORAGE_KEYS.responseData)
    setSubjects({});
    localStorage.removeItem(STORAGE_KEYS.subjects)
    setMarkedForReview({});
      

    // Proceed to step 2 (the timer & start button view)
    setStep(2);
  };

  const showSubmitScreen = () => {
    setShowSubmitView(true);
  };

  const goBackToQuestions = () => {
    setShowSubmitView(false);
  };

  const handleStartTest = () => {
    // if (!participantName || !rollNumber) {
    //   alert("कृपया अपना नाम और रोल नंबर दोनों दर्ज करें \n Please enter both your name and roll number.");
    //   return;
    // }
    const now = Date.now();
    setStartTime(now);
    localStorage.setItem(STORAGE_KEYS.startTime, now.toString());
    // Store participant details
    const participantData = {
      participant_name: participantName,
      roll_number: rollNumber,
    };
    localStorage.setItem('participantData', JSON.stringify(participantData));
    setHasStarted(true);
    // Initialize timer using test duration (in seconds)
    const totalTime = testData.duration_minutes * 60;
    setTimer(totalTime);
  };

  const [remainingTime, setRemainingTime] = useState<number>(0);
  useEffect(() => {
    if (testData) {
      const IST_OFFSET = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
      // Adjust testStartTime to IST by adding the offset
      const testStartTime = new Date(testData.start_time).getTime() - IST_OFFSET;
      const updateRemainingTime = () => {
        const now = Date.now();
        // console.log("Test start time (IST):", testStartTime, "Current time:", now);
        const diff = testStartTime - now;
        setRemainingTime(diff > 0 ? Math.floor(diff / 1000) : 0);
      };
      updateRemainingTime(); // initial update
      const timerInterval = setInterval(updateRemainingTime, 1000);
      return () => clearInterval(timerInterval);
    }
  }, [testData]);

  // Render logic
  if (!hasStarted) {
    if (step === 1) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-purple-100">
          <div className="text-center p-8 bg-white shadow-lg rounded-lg">
            <h1 className="text-4xl font-bold mb-4">Enter Your Details</h1>
            <input
              type="text"
              placeholder="Participant Name"
              value={participantName}
              onChange={(e) => setParticipantName(e.target.value)}
              className="mb-4 p-2 border rounded w-full max-w-xs mx-auto"
            />
            <input
              type="text"
              placeholder="Roll Number"
              value={rollNumber}
              onChange={(e) => setRollNumber(e.target.value)}
              className="mb-4 p-2 border rounded w-full max-w-xs mx-auto"
            />
            <button
              onClick={() => handleSaveDetails()}
              className="px-8 py-4 bg-blue-600 text-white rounded-lg text-xl hover:bg-blue-700"
            >
              Save and Next
            </button>
          </div>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-purple-100">
          <div className="text-center p-8 bg-white shadow-lg rounded-lg">
            <h1 className="text-4xl font-bold mb-4">Test Will Start Soon</h1>
            <p className="mb-8 text-xl">
              {remainingTime > 0
                ? `Test will start in: ${formatTime(remainingTime)}`
                : `Press the button below to start your test.`}
            </p>
            <button
              onClick={handleStartTest}
              disabled={remainingTime > 0}
              className={`px-8 py-4 ${remainingTime > 0 ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"} text-white rounded-lg text-xl`}
            >
              Start Test
            </button>
          </div>
        </div>
      );
    }
  }

  if (!testData || Object.keys(subjects).length === 0 || !currentSubject) {
    console.log(testData);
    console.log(Object.keys(subjects).length);
    console.log(currentSubject);
    return <div>Loading test data...</div>;
  }

  if (testSubmitted && responseData) {
    return <AttemptAnalysis />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
    {showSubmitView ? (
        // Show submit view with question states for all subjects
        <div className="max-w-6xl mx-auto px-4 py-6">
          <div className="bg-white rounded-lg shadow-sm border p-6">
            <h2 className="text-xl font-semibold mb-4">Test Submission Overview</h2>
            {Object.keys(subjects).map((subject) => (
              <div key={subject} className="mb-6">
                <h3 className="text-lg font-semibold">{subject}</h3>
                <div className="grid grid-cols-6 md:grid-cols-10 gap-2">
                  {Array.from({ length: subjects[subject].total }, (_, i) => {
                    const questionNumber = i + 1;
                    const isAnswered = Object.keys(answers[subject] || {}).includes(`${questionNumber}`);
                    const isMarkedForReview = markedForReview[subject]?.includes(questionNumber);
                    return (
                      <button
                        key={questionNumber}
                        className={`p-2 rounded-full text-center transition-colors ${
                          isMarkedForReview
                            ? 'bg-blue-100 text-blue-700'
                            : isAnswered
                            ? 'bg-green-100 text-green-700'
                            : 'bg-red-100 text-red-700'
                        }`}
                      >
                        {questionNumber}
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
            <div className="text-right">
              <button
                className="px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-700"
                onClick={handleSubmit}
              >
                Confirm Submit
              </button>
            </div>
            <div className="mt-4">
              <button
                className="px-6 py-3 bg-gray-600 text-white rounded-md hover:bg-gray-700"
                onClick={goBackToQuestions}
              >
                Back to Questions
              </button>
            </div>
          </div>
        </div>
      ) : (<>
      <div className="bg-white border-b sticky top-0 z-10">
        <div className="max-w-6xl mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold">Competitive Test</h1>
          <div className="flex items-center gap-3">
            {timer <= 300 && (
              <div className="flex items-center gap-2 text-red-600">
                <AlertCircle className="w-5 h-5" />
                <span>Less than 5 minutes remaining!</span>
              </div>
            )}
            <div className="flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-md">
              <Clock className="w-5 h-5" />
              <span className="font-mono text-lg">{formatTime(timer)}</span>
            </div>
          </div>
        </div>

        <div className="max-w-6xl mx-auto px-4 py-2">
          <div className="flex space-x-4 overflow-x-auto pb-2">
            {Object.entries(subjects).map(([subject, data]) => (
              <button
                key={subject}
                onClick={() => setCurrentSubject(subject)}
                className={`group flex-shrink-0 ${
                  currentSubject === subject
                    ? 'text-blue-600'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                <div className="px-4 py-2">
                  <div className="flex items-center gap-2 mb-1">
                    <span className="font-medium">{subject}</span>
                    <span className="text-sm text-gray-500">
                      {Object.keys(answers[subject] || {}).length || 0}/{data.total}
                    </span>
                  </div>
                  <div className="h-1 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className={`h-full transition-all duration-300 ${
                        currentSubject === subject
                          ? 'bg-blue-600'
                          : 'bg-gray-400 group-hover:bg-gray-600'
                      }`}
                      style={{ width: getProgressWidth(subject) }}
                    />
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto px-2 py-6">
        <div className="bg-white rounded-lg shadow-sm border p-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="font-semibold">{currentSubject} {currentQuestion}</h2>
            <span className="text-sm text-gray-500">
              Question {currentQuestion} of {subjects[currentSubject].total}
            </span>
          </div>

          <div className="space-y-6">
            <div className="">
              {(() => {
                const subjectData = testData.subjects.find((s: any) => s.name === currentSubject);
                const question = subjectData?.questions[currentQuestion - 1]?.question_text;
                const qid = subjectData?.questions[currentQuestion - 1]?.id;
                const options = subjectData?.questions[currentQuestion - 1]?.options || [];
                const selectedOption: number | null = answers[currentSubject]?.[currentQuestion] ?? null;

                return (
                  <div>
                    <p className="font-bold text-xl text-blue-800 mb-4">{question}</p>
                    <div className="space-y-3">
                      {options.map((option: string, i: number) => (
                        <label
                          key={i}
                          className={`flex items-center gap-3 p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-colors
                            ${selectedOption !== null && selectedOption !== i + 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                        >
                          <input
                            type="radio"
                            name={currentSubject + currentQuestion}
                            value={i + 1}
                            checked={selectedOption === i + 1}
                            onChange={() => handleAnswering(currentSubject, currentQuestion, qid, i)}
                          />
                          <span>{option}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                );
              })()}
            </div>

            <div className="flex justify-between items-center pt-6 text-sm">
              <button
                className="flex items-center gap-1 px-2 py-2 border rounded-md hover:bg-gray-50"
                onClick={() => setCurrentQuestion((prev) => Math.max(1, prev - 1))}
              >
                <ChevronLeft className="w-4 h-4" /> Previous
              </button>

              <div className="flex gap-1">
                <button
                  className="flex items-center justify-center gap-1 px-2 py-2 border rounded-md hover:bg-gray-50"
                  onClick={() => toggleMarkForReview(currentSubject, currentQuestion)}
                >
                  <BookmarkIcon className="w-4 h-4" />
                  Mark for Review
                </button>
                <button
                  className="flex items-center gap-1 px-2 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  onClick={handleNextQuestion}
                >
                  Next <ChevronRight className="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 bg-white rounded-lg shadow-sm border p-6">
          <h3 className="text-lg font-semibold mb-4">Question Palette</h3>
          <div className="grid grid-cols-6 md:grid-cols-10 gap-2">
            {Array.from({ length: subjects[currentSubject].total }, (_, i) => {
              const questionNumber = i + 1;
              const isAnswered = Object.keys(answers[currentSubject] || {}).includes(`${questionNumber}`);
              const isMarkedForReview = markedForReview[currentSubject]?.includes(questionNumber);
              const isCurrent = currentQuestion === questionNumber;

              return (
                <button
                  key={questionNumber}
                  onClick={() => setCurrentQuestion(questionNumber)}
                  className={`p-2 rounded-full text-center transition-colors ${
                    isMarkedForReview
                      ? 'bg-blue-100 text-blue-700'
                      : isAnswered
                      ? 'bg-green-100 text-green-700'
                      : isCurrent
                      ? 'bg-gray-300'
                      : 'bg-red-100 text-red-700'
                  }`}
                >
                  {questionNumber}
                </button>
              );
            })}
          </div>
        </div>

        <div className="mt-6 bg-white rounded-lg shadow-sm border p-6 text-right">
          <button
            className="px-6 py-3 bg-red-600 text-white rounded-md hover:bg-red-700"
            onClick={showSubmitScreen}
          >
            Go to Submit Test
          </button>
        </div>
      </div>
      </>)}
    </div>
  );
};

export default TestTakingUI;