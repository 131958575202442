import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

interface Course {
  name: string;
  classes: { [key: string]: string };
  icon: string;
}

const coursesData: Course[] = [
  {
    name: "SCIENCE",
    classes: {
      "Class 11": "SCIENCE 11",
      "Class 12": "SCIENCE 12",
    },
    icon: "/assets/imgsci5.png",
  },
  {
    name: "ARTS",
    classes: {
      "Class 11": "ARTS 11",
      "Class 12": "ARTS 12",
    },
    icon: "/assets/imgsci2.png",
  },
  {
    name: "FOUNDATION",
    classes: {
      "Class 10": "CLASS 10",
      "Class 9": "CLASS 9",
      "Class 8": "CLASS 8",
      // "More": "ALL",
    },
    icon: "/assets/imgsci7.png",
  },
  // {
  //   name: "Entrance Exams",
  //   classes: {
  //     "AMU": "AMU",
  //     "BHU": "BHU",
  //     // "D.El.Ed": "DElEd",
  //     // "More": "MORE",
  //   },
  //   icon: "/assets/imgsci7.png",
  // },
  {
    name: "Problem Solving",
    classes: {
      "NEET 11": "NEET 11",
      "NEET 12": "NEET 12",
      // "NTSE": "NTSE",
      // "More": "ProblemSolvingMore",
    },
    icon: "/assets/imgsci7.png",
  },
];


const ExploreByExam = () => {
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    setCourses(coursesData); // Fetch data from API here
  }, []);

  return (
    <div className="container mx-auto px-4">
      
      <div className="flex flex-wrap justify-center items-center">
        {courses.map((course) => (
          <div
            key={course.name}
            className="min-w-80 max-w-96 bg-white border-2 rounded-2xl shadow-xl p-6 my-4 mx-6 "
          >
            <h3 className="text-2xl font-bold mb-4">{course.name}</h3>
            <div className="w-full flex">
              <div className="w-full md:w-2/3">
            <div className="grid grid-cols-2 gap-4 ">
              {Object.entries(course.classes).map(([className, value]) => (
                <Link to={`/coursesPage/${value}`} key={className}>
                  <button
                    className="w-24 bg-white hover:bg-gray-200 border-2 border-gray-200 text-gray-800 py-2 px-4 rounded-full"
                  >
                    {className}
                  </button>
                </Link>
              ))}

            </div>
            <img
              src={course.icon}
              alt={course.name}
              className="w-full md:hidden py-2"
            />
            <div className="flex text-black items-center justify-start mt-2 md:mt-4 ">
              <div>Explore more</div>
              <button className="text-black py-2 px-4 rounded-full bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                  <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                </svg>
            </button>
            </div>
            </div>
            <div className="hidden md:flex w-1/3 h-full mx-auto mb-4 p-3 md:p-0 items-center justify-center">
            <img
              src={course.icon}
              alt={course.name}
              className="object-cover"
            />
            </div>
            
            </div>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExploreByExam;
