import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Login: React.FC = () => {
  // Language state
  const [language, setLanguage] = useState<'en' | 'hi'>('en');
  const { isAuthenticated } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  // Translations
  const translations = {
    en: {
      title: "Login",
      phoneNumber: "Phone Number",
      phoneExample: "Enter your 10-digit phone number",
      password: "Password",
      passwordExample: "Enter your password",
      loginButton: "Login",
      noAccount: "Don't have an account?",
      signUp: "Sign Up",
      forgotPassword: "Forgot Password?",
      fillAllFields: "Please enter both phone number and password.",
      loginFailed: "Login failed. Please check your credentials and try again.",
      invalidPhone: "Please enter a valid 10-digit phone number."
    },
    hi: {
      title: "लॉगिन",
      phoneNumber: "फोन नंबर",
      phoneExample: "अपना 10 अंकों का फोन नंबर दर्ज करें",
      password: "पासवर्ड",
      passwordExample: "अपना पासवर्ड दर्ज करें",
      loginButton: "लॉगिन करें",
      noAccount: "खाता नहीं है?",
      signUp: "साइन अप करें",
      forgotPassword: "पासवर्ड भूल गए?",
      fillAllFields: "कृपया फोन नंबर और पासवर्ड दोनों दर्ज करें।",
      loginFailed: "लॉगिन विफल। कृपया अपने क्रेडेंशियल्स जांचें और पुनः प्रयास करें।",
      invalidPhone: "कृपया एक वैध 10 अंकों का फोन नंबर दर्ज करें।"
    }
  };

  const t = translations[language];

  useEffect(() => {
    if(isAuthenticated){
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const validatePhoneNumber = (phone: string) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    // Validate inputs
    if (!phoneNumber || !password) {
      setError(t.fillAllFields);
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      setError(t.invalidPhone);
      return;
    }

    try {
      setLoading(true);
      await login(phoneNumber, password);
      navigate('/usersPage');
    } catch (error) {
      console.error('Login error:', error);
      setError(t.loginFailed);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-100 to-purple-100 p-4">
      <div className="bg-white p-8 rounded-2xl shadow-2xl w-full max-w-md transition-all duration-300 hover:shadow-lg">
        {/* Header with language toggle */}
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-blue-600">{t.title}</h2>
          <div className="flex items-center space-x-2">
            <button 
              onClick={() => setLanguage('en')} 
              className={`px-3 py-1 rounded-full text-sm font-medium transition-all duration-200 ${language === 'en' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            >
              English
            </button>
            <button 
              onClick={() => setLanguage('hi')} 
              className={`px-3 py-1 rounded-full text-sm font-medium transition-all duration-200 ${language === 'hi' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
            >
              हिंदी
            </button>
          </div>
        </div>

        {/* Login illustration */}
        <div className="flex justify-center mb-6">
          <div className="w-32 h-32 bg-blue-100 rounded-full flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </div>

        {/* Error message */}
        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-lg animate-pulse">
            <p>{error}</p>
          </div>
        )}

        <form onSubmit={handleLogin} className="space-y-6">
          {/* Phone Number Input */}
          <div>
            <label className="block text-gray-700 text-sm font-medium mb-2">{t.phoneNumber} *</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
              </div>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={t.phoneExample}
                className="block w-full pl-10 pr-3 py-3 bg-gray-50 border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white transition-all duration-200"
                maxLength={10}
                pattern="[6-9][0-9]{9}"
              />
            </div>
            <p className="text-xs text-gray-500 mt-1">
              {language === 'en' ? "Start with 6, 7, 8, or 9 followed by 9 digits" : "6, 7, 8, या 9 से शुरू करके 9 अंक और डालें"}
            </p>
          </div>

          {/* Password Input */}
          <div>
            <label className="block text-gray-700 text-sm font-medium mb-2">{t.password} *</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t.passwordExample}
                className="block w-full pl-10 pr-3 py-3 bg-gray-50 border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white transition-all duration-200"
              />
            </div>
            <div className="flex justify-end mt-1">
              <a href="#" className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-200">
                {t.forgotPassword}
              </a>
            </div>
          </div>

          {/* Login Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full flex justify-center items-center py-3 px-4 bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium rounded-xl hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 ${loading ? 'opacity-70 cursor-not-allowed' : ''}`}
          >
            {loading ? (
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : null}
            {loading ? (language === 'en' ? 'Logging in...' : 'लॉगिन हो रहा है...') : t.loginButton}
          </button>
        </form>

        {/* Sign Up Link */}
        <div className="text-center mt-8">
          <p className="text-gray-600">
            {t.noAccount} <Link to="/signup" className="font-medium text-blue-600 hover:text-blue-800 transition-colors duration-200">{t.signUp}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;