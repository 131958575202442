import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HindiEnglishCourseCard = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [showMore, setShowMore] = useState(false);
  
  const courseData = {
    title: "Basic English Course",
    instructor: "MG Hussain Sir",
    rating: 4.9,
    reviewCount: 3250,
    imageUrl: "https://naendapi.naecoaching.com/media/courses/NAE_COACHING.png",
    level: "शुरुआती - Beginner",
    duration: "30 घंटे - 30 hours",
    badges: ["निःशुल्क - Free", "लोकप्रिय - Popular"],
    topics: ["LSRW Skills", "स्पीकिंग - Speaking", "समझना - Understanding"],
    description: "आत्मविश्वास से अंग्रेजी बोलना और समझना सीखें। रोज़मर्रा की बातचीत पर विशेष ध्यान। भाषा के बुनियादी नियमों और व्याकरण का परिचय। प्रैक्टिकल अभ्यास और वास्तविक जीवन के उदाहरण।",
    descriptionEn: "Learn to speak and understand English with confidence. Special focus on everyday conversation. Introduction to basic language rules and grammar. Practical exercises and real-life examples."
  };
  
  // Generate gradient background
  const gradient = "from-blue-500 to-purple-600";
  
  // Render star rating
  const renderStars = (rating: number) => {
    return (
      <div className="flex">
        {[1, 2, 3, 4, 5].map((star) => (
          <span key={star} className={star <= Math.round(rating) ? "text-yellow-400" : "text-gray-300"}>
            ★
          </span>
        ))}
      </div>
    );
  };
  
  const toggleShowMore = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowMore(!showMore);
  };
  
  return (
    <div className="w-full mx-auto px-2 md:px-4 lg:px-8 xl:px-32">
      <div 
        className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-xl flex flex-col lg:flex-row"
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
      >
        {/* Image Container - Full width on mobile, 1/3 width on desktop */}
        <div className="relative lg:w-1/2 h-auto bg-gradient-to-r from-blue-500 to-purple-400 md:flex md:items-center md:justify-center overflow-hidden">
          {/* Image Container with fixed aspect ratio for mobile */}
          <div className="w-full lg:h-full">
            <img 
              src={courseData.imageUrl} 
              alt={courseData.title}
              className="w-full h-full lg:h-auto object-cover mix-blend-overlay opacity-90"
            />
          </div>
          
          {/* Badges */}
          <div className="absolute top-3 left-3 flex gap-2 flex-wrap">
            {courseData.badges.map((badge, index) => (
              <span 
                key={index} 
                className="px-2 py-1 bg-white/90 text-xs font-bold rounded-full shadow-sm text-purple-700"
              >
                {badge}
              </span>
            ))}
          </div>
        </div>
        
        {/* Card Content */}
        <div className="p-5 flex-1 md:w-2/3">
          <h3 className="text-xl font-bold text-gray-800">{courseData.title}</h3>
          
          <p className="text-sm text-gray-600 mt-2">द्वारा / by {courseData.instructor}</p>
          
          <div className="flex items-center mt-2">
            <span className="text-amber-500 font-bold mr-1">{courseData.rating}</span>
            {renderStars(courseData.rating)}
            <span className="text-xs text-gray-500 ml-1">({courseData.reviewCount})</span>
          </div>
          
          <div className="flex flex-wrap items-center mt-3 text-sm text-gray-600">
            <span className="flex items-center mr-4 mb-2 md:mb-0">
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {courseData.duration}
            </span>
            <span className="flex items-center">
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
              {courseData.level}
            </span>
          </div>
          
          {/* Description with Show More/Less functionality */}
          <div className="mt-3">
            <p className={`text-sm text-gray-700 transition-all duration-300 ${showMore ? '' : 'line-clamp-2'}`}>
              {courseData.description}
              <br />
              <span className="text-xs text-gray-500">{courseData.descriptionEn}</span>
            </p>
            <button 
              onClick={toggleShowMore} 
              className="text-blue-600 text-xs font-medium mt-1 hover:underline"
            >
              {showMore ? 'कम दिखाएं / Show Less' : 'और दिखाएं / Show More'}
            </button>
          </div>
          
          {/* LSRW Skills Highlight */}
          <div className="mt-3 bg-blue-50 p-2 rounded-lg">
            <h4 className="text-sm font-bold text-blue-800">LSRW कौशल / Skills:</h4>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-1 mt-1">
              <div className="flex items-center">
                <span className="w-2 h-2 bg-blue-500 rounded-full mr-1"></span>
                <span className="text-xs">सुनना / Listening</span>
              </div>
              <div className="flex items-center">
                <span className="w-2 h-2 bg-green-500 rounded-full mr-1"></span>
                <span className="text-xs font-bold">बोलना / Speaking</span>
              </div>
              <div className="flex items-center">
                <span className="w-2 h-2 bg-yellow-500 rounded-full mr-1"></span>
                <span className="text-xs">पढ़ना / Reading</span>
              </div>
              <div className="flex items-center">
                <span className="w-2 h-2 bg-purple-500 rounded-full mr-1"></span>
                <span className="text-xs">लिखना / Writing</span>
              </div>
            </div>
          </div>
          
          {/* Topics tags */}
          <div className="flex flex-wrap gap-1 mt-3">
            {courseData.topics.map((topic, index) => (
              <span 
                key={index} 
                className="px-2 py-1 bg-blue-100 text-blue-700 text-xs rounded-full"
              >
                {topic}
              </span>
            ))}
          </div>
          
          {/* Price and CTA */}
          <div className="mt-5 pt-4 border-t border-gray-100 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3">
            <div>
              <span className="text-lg font-bold text-green-600">निःशुल्क / Free</span>
              <span className="text-sm text-gray-500 ml-2 block sm:inline">सभी छात्रों के लिए</span>
            </div>
            
            <Link 
              to="/coursePage/32"
              className={`px-4 py-2 rounded-lg font-medium text-sm text-white bg-gradient-to-r ${gradient} transition-all hover:shadow-md w-full sm:w-auto text-center`}
            >
              अभी जुड़ें / Join Now
            </Link>
          </div>
        </div>
      </div>

      {/* Quick Preview Overlay (appears on hover) - Only visible on larger screens */}
      <div 
        className={`absolute inset-0 bg-gradient-to-t from-black/80 to-transparent flex-col justify-end p-5 transition-opacity duration-300 hidden md:flex ${
          isHovered ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
      >
        <h3 className="text-lg font-bold text-white">{courseData.title}</h3>
        <p className="text-white/80 text-sm my-2">
          {courseData.description}
          <br />
          <span className="text-xs text-white/60">{courseData.descriptionEn}</span>
        </p>
        
        <div className="flex gap-2 mt-3">
          <Link to={'/coursePage/32'} className="flex-1 bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 rounded-lg font-medium text-sm hover:from-blue-600 hover:to-purple-700 transition-colors text-center">
            अभी जुड़ें / Join Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HindiEnglishCourseCard;