import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Cookies from 'js-cookie';
import { getOrCreateDeviceUUID } from '../components/DeviceActivity';

interface OtpError {
  error: string;
  [key: string]: any;
}

const Signup: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  // Language toggle state
  const [language, setLanguage] = useState<'en' | 'hi'>('en');

  // Translations
  const translations = {
    en: {
      title: "Sign Up",
      firstName: "First Name",
      lastName: "Last Name",
      phoneNumber: "Phone Number",
      phoneExample: "Ex: 9876543210",
      classLabel: "Choose your Class",
      password: "Password",
      confirmPassword: "Confirm Password",
      passwordRequirements: "Password must be at least 6 characters",
      enterOtp: "Enter OTP sent to your phone",
      otpExample: "Ex: 123456",
      back: "Back",
      next: "Next",
      getOtp: "Get OTP",
      verifyOtp: "Verify OTP",
      passwordsDontMatch: "Passwords do not match",
      invalidPhone: "Invalid phone number. Must be 10 digits starting with 6-9",
      fillAllFields: "Please fill in all fields correctly",
      otpFailed: "OTP verification failed. Try again.",
      submit: "Submit"
    },
    hi: {
      title: "साइन अप करें",
      firstName: "पहला नाम",
      lastName: "अंतिम नाम",
      phoneNumber: "फोन नंबर",
      phoneExample: "उदाहरण: 9876543210",
      classLabel: "अपनी कक्षा चुनें",
      password: "पासवर्ड",
      confirmPassword: "पासवर्ड की पुष्टि करें",
      passwordRequirements: "पासवर्ड कम से कम 6 अक्षर का होना चाहिए",
      enterOtp: "आपके फोन पर भेजा गया OTP दर्ज करें",
      otpExample: "उदाहरण: 123456",
      back: "वापस",
      next: "अगला",
      getOtp: "OTP प्राप्त करें",
      verifyOtp: "OTP सत्यापित करें",
      passwordsDontMatch: "पासवर्ड मेल नहीं खाते",
      invalidPhone: "अमान्य फोन नंबर। 6-9 से शुरू होने वाले 10 अंक होने चाहिए",
      fillAllFields: "कृपया सभी फ़ील्ड सही ढंग से भरें",
      otpFailed: "OTP सत्यापन विफल। पुनः प्रयास करें।",
      submit: "Submit करें"
    }
  };

  const t = translations[language];

  useEffect(() => {
    if(isAuthenticated){
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: 'noemail@mail.needed',
    phoneNumber: '',
    student_class: 'CLASS 6', 
    subjects: '',
    qualifications: '',
    experience: '',
    password: '',
    confirmPassword: '',
    role: 'student'
  });
  
  const [deviceId, setDeviceId] = useState<string>(Cookies.get('device_uuid') || '');
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [runCount, setRunCount] = useState(0);
  const { signup, login, sendOtp, verifyOtp } = useAuth();

  useEffect(() => {
    if (deviceId === '') {
      if (runCount < 1) {
        getOrCreateDeviceUUID();
        setDeviceId(Cookies.get('device_uuid') || '')
      }
      if (runCount < 2) {
        setDeviceId(Cookies.get('device_uuid') || '')
        setRunCount(runCount + 1);
      }
    }
  }, [runCount, deviceId]);
  
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(phoneNumber);
  };
  
  const handleSendOtp = async () => {
    try {
      if (deviceId !== '') {
        const responseData = await sendOtp(formData.phoneNumber, deviceId);
        setError(responseData.message);
        setOtpSent(true);
        setStep(6);
      }
    } catch (error) {
      const err = error as Error;
      setError(err.message || (language === 'en' ? 'Failed to send OTP. Try again.' : 'OTP भेजने में विफल। पुनः प्रयास करें।'));
    }
  };
  
  const handleVerifyOtp = async () => {
    try {
      const verification = await verifyOtp(formData.phoneNumber, otp);
      
      if (verification.verified) {
        setError(verification.message);
        handleSubmit();
      } else {
        setError(language === 'en' ? 'OTP did not get verified, please enter correct OTP.' : 'OTP सत्यापित नहीं हुआ, कृपया सही OTP दर्ज करें।');
      }
    } catch (error) {
      const err = error as OtpError;
      setError(err.error || t.otpFailed);
    }
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleNext = async () => {
    if (step === 1 && formData.firstName && formData.lastName) {
      setStep(3);
    } else if (step === 2 && formData.email) {
      if (!isValidEmail(formData.email)) {
        setError(language === 'en' ? 'Invalid email format.' : 'अमान्य ईमेल प्रारूप।');
        return;
      }
      setError('');
      setStep(3);
    } else if (step === 3 && formData.student_class) {
      setStep(4);
    } else if (step === 4) {
      if (formData.password.length < 6) {
        setError(language === 'en' ? 'Password must be at least 6 characters long.' : 'पासवर्ड कम से कम 6 अक्षर का होना चाहिए।');
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        setError(t.passwordsDontMatch);
        return;
      }
      setError('');
      setStep(5);
    } else if (step === 5 && formData.phoneNumber) {
      if (!isValidPhoneNumber(formData.phoneNumber)) {
        setError(t.invalidPhone);
        return;
      }
      setError('');
      // handleSendOtp();
      handleSubmit();
    } else {
      setError(t.fillAllFields);
    }
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleSubmit = async () => {
    try {
      await signup(formData);
      await login(formData.phoneNumber, formData.password);
      navigate('/usersPage');
    } catch (err) {
      setError(language === 'en' ? 'Signup failed. Please try again.' : 'साइनअप विफल। कृपया पुनः प्रयास करें।');
    }
  };

  const getProgressWidth = () => {
    return `${(step / 6) * 100}%`;
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 rounded-2xl shadow-2xl w-full max-w-md">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-blue-600">{t.title}</h2>
          <div className="flex items-center space-x-2">
            <button 
              onClick={() => setLanguage('en')} 
              className={`px-2 py-1 rounded ${language === 'en' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              English
            </button>
            <button 
              onClick={() => setLanguage('hi')} 
              className={`px-2 py-1 rounded ${language === 'hi' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              हिंदी
            </button>
          </div>
        </div>
        
        {/* Progress indicator */}
        <div className="w-full bg-gray-200 rounded-full h-2 mb-6">
          <div 
            className="bg-blue-600 h-2 rounded-full transition-all duration-300" 
            style={{ width: getProgressWidth() }}
          ></div>
        </div>
        
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded">
            <p>{error}</p>
          </div>
        )}

        <div className="space-y-4">
          {step === 1 && (
            <>
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-1">{t.firstName} *</label>
                <input
                  name="firstName"
                  placeholder={language === 'en' ? "Ex: John" : "उदाहरण: राहुल"}
                  value={formData.firstName}
                  onChange={handleChange}
                  className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-1">{t.lastName} *</label>
                <input
                  name="lastName"
                  placeholder={language === 'en' ? "Ex: Smith" : "उदाहरण: शर्मा"}
                  value={formData.lastName}
                  onChange={handleChange}
                  className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </>
          )}

          {step === 3 && (
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1">{t.classLabel} *</label>
              <select
                name="student_class"
                onChange={handleChange}
                className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={formData.student_class}
              >
                <option value="CLASS 6">CLASS 6</option>
                <option value="CLASS 7">CLASS 7</option>
                <option value="CLASS 8">CLASS 8</option>
                <option value="CLASS 9">CLASS 9</option>
                <option value="CLASS 10">CLASS 10</option>
                <option value="SCIENCE 11">SCIENCE 11</option>
                <option value="SCIENCE 12">SCIENCE 12</option>
                <option value="ARTS 11">ARTS 11</option>
                <option value="ARTS 12">ARTS 12</option>
              </select>
            </div>
          )}

          {step === 4 && (
            <>
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-1">{t.password} *</label>
                <input
                  name="password"
                  type="password"
                  placeholder={language === 'en' ? "••••••" : "••••••"}
                  value={formData.password}
                  onChange={handleChange}
                  className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <p className="text-xs text-gray-500 mt-1">{t.passwordRequirements}</p>
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-1">{t.confirmPassword} *</label>
                <input
                  name="confirmPassword"
                  type="password"
                  placeholder={language === 'en' ? "••••••" : "••••••"}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </>
          )}

          {step === 5 && (
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1">{t.phoneNumber} *</label>
              <input
                name="phoneNumber"
                placeholder={t.phoneExample}
                value={formData.phoneNumber}
                onChange={handleChange}
                className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="tel"
                pattern="[6-9][0-9]{9}"
                maxLength={10}
                required
              />
              <p className="text-xs text-gray-500 mt-1">
                {language === 'en' 
                  ? "Enter a 10-digit number starting with 6, 7, 8, or 9" 
                  : "6, 7, 8, या 9 से शुरू होने वाला 10 अंकों का नंबर दर्ज करें"}
              </p>
            </div>
          )}

          {step === 6 && otpSent && (
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1">{t.enterOtp}</label>
              <input 
                type="text" 
                placeholder={t.otpExample}
                onChange={(e) => setOtp(e.target.value)}
                className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={6}
                pattern="[0-9]*"
              />
              <p className="text-xs text-gray-500 mt-1">
                {language === 'en'
                  ? "Enter the 6-digit code sent to your phone"
                  : "अपने फोन पर भेजे गए 6 अंकों के कोड को दर्ज करें"}
              </p>
            </div>
          )}

          <div className="flex justify-between mt-6">
            {step > 1 && (
              <button
                type="button"
                onClick={handleBack}
                className="px-6 py-2 bg-gray-500 text-white rounded-xl hover:bg-gray-600 transition duration-200"
              >
                {t.back}
              </button>
            )}
            {step < 6 && (
              <button
                type="button"
                onClick={handleNext}
                className="px-6 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition duration-200 ml-auto"
              >
                {step === 5 ? t.submit : t.next}
              </button>
            )}
            {step === 6 && (
              <button
                type="button"
                onClick={handleVerifyOtp}
                className="px-6 py-2 bg-blue-500 text-white rounded-xl hover:bg-blue-600 transition duration-200 ml-auto"
              >
                {t.verifyOtp}
              </button>
            )}
          </div>
        </div>
        
        {/* Step indicator */}
        <div className="flex justify-center mt-6">
          <div className="text-sm text-gray-500">
            {language === 'en' 
              ? `Step ${step} of 6` 
              : `चरण ${step} / 6`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;