import React, { useState, useEffect, useRef } from 'react';
import { getCourseDetails } from '../components/CourseDetailApiService';
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getYouTubeVideoId } from '../lib/getYouTubeVideoId'
import axios from 'axios';
import { Helmet } from 'react-helmet';


const YouTubeIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="100%" 
    fill="currentColor" 
    className="bi bi-youtube" 
    viewBox="0 0 16 16"
  >
    <path 
      d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"
    />
  </svg>
);

const API_URL = process.env.REACT_APP_API_URL;

interface User{
  first_name: string;
  last_name:string;
}

interface Teacher {
  user: User
}

interface Lecture {
  id: number;
  topic: string;
  description: string;
  is_unlocked: boolean;
  yt_link_ref: string;
  cnotes_ref: string | null;
  notes_ref: string | null;
  dpp_ref: string | null;
  is_locked: boolean;
  chapter: number;
  created_by: Teacher;
}

interface Chapter {
  id: number;
  title: string;
  yt_link_ref: string | null;
  course: number;
  lectures: Lecture[];
}

interface CourseData {
  id: number;
  class_for: Array<{ name: string }>;
  teacher: {
    user: {
      username: string;
      first_name: string;
      last_name: string;
    };
    qualifications: string;
    subjects: string;
    experience: string;
  };
  course_name: string;
  description: string;
  course_img: string;
  mode: string;
  price: string;
  discount_percentage: string;
  chapters: Chapter[];
}

// Modal component for authentication/student selection
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  buttonText: string;
  buttonLink: string;
  language: 'en' | 'hi';
  toggleLanguage: () => void;
}

const Modal: React.FC<ModalProps> = ({ 
  isOpen, 
  onClose, 
  title, 
  message, 
  buttonText, 
  buttonLink,
  language,
  toggleLanguage
}) => {
  const navigate = useNavigate();
  
  if (!isOpen) return null;

  const translations = {
    en: {
      title,
      message,
      buttonText,
      languageButton: "हिंदी में देखें"
    },
    hi: {
      title: title === "Login Required" ? "लॉगिन आवश्यक है" : "छात्र का चयन करें",
      message: title === "Login Required" 
        ? "कोर्स सामग्री देखने के लिए कृपया पहले लॉगिन करें।" 
        : "कृपया पहले अपने छात्र प्रोफ़ाइल का चयन करें।",
      buttonText: title === "Login Required" ? "लॉगिन करें" : "छात्र पेज पर जाएँ",
      languageButton: "View in English"
    }
  };

  const currentLang = translations[language];

  const handleButtonClick = () => {
    navigate(buttonLink);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-xl max-w-md w-full transform transition-all animate-fadeIn">
        <div className="bg-gradient-to-r from-purple-500 to-blue-500 rounded-t-xl p-4">
          <h2 className="text-xl font-bold text-white">{currentLang.title}</h2>
          <button 
            onClick={toggleLanguage}
            className="absolute top-4 right-4 bg-white bg-opacity-20 hover:bg-opacity-30 text-white font-medium py-1 px-3 rounded-lg text-sm transition duration-300"
          >
            {currentLang.languageButton}
          </button>
        </div>
        
        <div className="p-6">
          <p className="text-gray-700 font-medium text-lg mb-6">{currentLang.message}</p>
          
          <div className="flex justify-between">
            <button 
              onClick={onClose}
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-lg transition duration-300"
            >
              {language === 'en' ? 'Cancel' : 'रद्द करें'}
            </button>
            
            <button 
              onClick={handleButtonClick}
              className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg shadow transition duration-300"
            >
              {currentLang.buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExpandableSection: React.FC<{ 
  chapter: Chapter, 
  course_img: string, 
  isAuthenticated: boolean, 
  studentId: number | null,
  openLoginModal: () => void,
  openStudentModal: () => void
}> = ({ 
  chapter, 
  course_img, 
  isAuthenticated,
  studentId,
  openLoginModal,
  openStudentModal
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleClickOutside = (event: MouseEvent) => {
    if (sectionRef.current && !sectionRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const videoId = chapter.yt_link_ref ? getYouTubeVideoId(chapter.yt_link_ref) :'kRcnWWHiuIk';
  const thumbnailUrl = chapter.yt_link_ref ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : course_img;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (!isAuthenticated) {
      openLoginModal();
    } else if (studentId === null) {
      openStudentModal();
    } else {
      navigate(`/chapter/${chapter.id}`, { state: chapter });
    }
  };

  return (
    <div onClick={handleClick} id={chapter.title} className="container mx-auto p-1 md:p-4 flex justify-center items-center cursor-pointer">
      <div className="w-full md:max-w-2xl">
        <div ref={sectionRef} style={{ position: 'relative' }} className="my-1 rounded-xl bg-emerald-100 shadow-emerald-950 shadow-sm hover:shadow-md transition-all duration-300">
          <div className="rounded-xl shadow-emerald-100 shadow-md p-1 md:p-2 bg-emerald-50">
            <div className="flex flex-row">
              <div className="w-full max-w-32 min-w-32 mr-1 md:mr-4">
                <div className="relative">
                  <img
                    src={thumbnailUrl}
                    alt={chapter.title}
                    className="object-cover w-full h-full max-w-32 max-h-20 rounded-xl"
                  />
                  {chapter.yt_link_ref ? (
                    <div className="absolute bottom-4 left-4 bg-white rounded-xl px-2 py-1 text-sm font-semibold flex justify-start items-center space-x-4">
                      {chapter.yt_link_ref ? (<>Introduction Lecture</>) :(<>very soon intro</>) }
                      <div className='text-red-600 w-6'>
                        <YouTubeIcon />
                      </div>
                    </div>
                  ) : (<></>)}
                  
                  <div className="absolute inset-0 w-full h-full"></div>
                </div>
              </div>
              <div className="w-auto flex flex-col justify-start">
                <div className="pl-2 py-1 lg:pl-8">
                  <p className="text-md md:text-lg text-wrap font-semibold md:font-bold">{chapter.title}</p>
                </div>
                <div className='flex justify-start items-center'>
                  <div className="w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between">
                    <div className="bg-white rounded-xl shadow-xl px-2 py-1 text-sm font-semibold">
                      {chapter.lectures.length} Lectures
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Define the type for route parameters
interface CourseParams {
  courseId: string;
}

const CourseDetailPage: React.FC = () => {
  const params = useParams();
  const courseId = parseInt(params.courseId ?? '', 10);
  const [courseData, setCourseData] = useState<CourseData | null>(null);
  const { studentId, enrolledCourses, authIntriger, setAuthIntriger, isAuthenticated } = useAuth();
  const [isLoginModalOpen, setLoginModalOpen] = useState<boolean>(false);
  const [isStudentModalOpen, setStudentModalOpen] = useState<boolean>(false);
  const [language, setLanguage] = useState<'en' | 'hi'>('en');

  // Check if the current course is already enrolled
  const isEnrolled = enrolledCourses.includes(courseId);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {        
        const data = studentId ? await getCourseDetails(courseId, studentId) : await getCourseDetails(courseId);
        setCourseData(data);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchCourseData();
  }, [courseId, studentId, isAuthenticated, authIntriger]);

  const handleEnroll = async () => {
    const token = localStorage.getItem('token');
    
    if (!isAuthenticated) {
      setLoginModalOpen(true);
      return;
    }
    
    if (studentId === null) {
      setStudentModalOpen(true);
      return;
    }
    
    if (discountedPrice === 0) {
      try {
        const response = await axios.post(
          `${API_URL}/courses/enrollments/`,
          {
            "student": studentId,
            "course": courseId
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        if(response.status === 201){
          setAuthIntriger(authIntriger ? false : true);
          alert(language === 'en' ? 'Enrollment successful!' : 'नामांकन सफल!');
        }
      } catch (error) {
        console.error('Error during enrollment:', error);
        alert(language === 'en' ? 'Failed to enroll. Please try again later.' : 'नामांकन विफल। कृपया बाद में पुनः प्रयास करें।');
      }
    } else {
      alert(language === 'en' ? 'You have to pay first.' : 'आपको पहले भुगतान करना होगा।');
    }
  };
    
  const discountedPrice = courseData ? (
    parseFloat(courseData.price) -
    (parseFloat(courseData.price) * parseFloat(courseData.discount_percentage)) / 100
  ) : 0;

  const toggleLanguage = () => {
    setLanguage(prev => prev === 'en' ? 'hi' : 'en');
  };

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const openStudentModal = () => {
    setStudentModalOpen(true);
  };

  return (
    <div className="container mx-auto p-4">
      <Helmet>
        <title>{courseData?.course_name ? `${courseData.course_name} N A E COACHING` : "N A E COACHING"}</title>
        <meta name="description" content={courseData?.course_name ? `${courseData.course_name} N A E COACHING` : "Bihar board online and offline coaching for Science, Arts and Matric. Offline Coaching in Thakurganj, Kishanganj (Bihar)."} />
      </Helmet>
      
      {/* Login Modal */}
      <Modal 
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        title="Login Required"
        message="Please login first to see the content of this course."
        buttonText="Go to Login"
        buttonLink="/login"
        language={language}
        toggleLanguage={toggleLanguage}
      />
      
      {/* Student Selection Modal */}
      <Modal 
        isOpen={isStudentModalOpen}
        onClose={() => setStudentModalOpen(false)}
        title="Select Student"
        message="Please select a student profile first."
        buttonText="Go to Student Page"
        buttonLink="/usersPage"
        language={language}
        toggleLanguage={toggleLanguage}
      />
      
      {courseData ? (
        <>
          <div id={courseData.course_name} className="text-center my-4 flex flex-col lg:flex-row items-start">
            <img
              src={courseData.course_img || 'https://i.ibb.co/yfQ6BxX/locked.jpg'}
              alt={courseData.course_name}
              className="w-auto max-h-96 mx-auto rounded-xl shadow-lg transition-transform duration-300 hover:scale-105"
            />
            <div className='flex justify-center w-full'> 
              <div className='relative bottom-0 bg-white self-center w-full rounded-xl shadow-xl lg:shadow-sm py-4 px-4 lg:ml-4'>
                <h1 className="text-2xl font-bold my-2 text-purple-700">{courseData.course_name}</h1>
                <p className="text-lg text-gray-700">{courseData.description}</p>
                
                {!isEnrolled && (
                  <>
                    <div className="text-lg font-semibold mt-4 text-gray-800">
                      {language === 'en' ? 'Course Price:' : 'कोर्स मूल्य:'} ₹{courseData.price}
                    </div>
                    <div className="text-lg font-semibold mt-1">
                      <span className='text-2xl font-bold text-red-500'>{courseData.discount_percentage}% {language === 'en' ? 'Offer' : 'छूट'}</span> 
                    </div>
                    <div className="text-lg font-semibold mt-1">
                      {language === 'en' ? 'Pay' : 'केवल'} <span className='text-3xl font-bold text-purple-600'>₹{discountedPrice}</span> {language === 'en' ? 'only' : 'का भुगतान करें'}
                    </div>
                    <div className="text-lg font-semibold mt-1 text-gray-700">
                      {language === 'en' ? 'Mode:' : 'प्रकार:'} {courseData.mode}
                    </div>
                    <button
                      onClick={handleEnroll}
                      className="mt-4 bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 text-white font-bold py-2 px-6 rounded-xl shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1"
                    >
                      {language === 'en' ? 'Enroll Now' : 'अभी दाखिला लें'}
                    </button>
                    <button
                      onClick={toggleLanguage}
                      className="mt-4 ml-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-4 rounded-lg transition duration-300"
                    >
                      {language === 'en' ? 'हिंदी में देखें' : 'View in English'}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="my-4">
            {courseData.chapters.map(chapter => (
              <ExpandableSection 
                key={chapter.id} 
                chapter={chapter} 
                course_img={courseData.course_img}
                isAuthenticated={isAuthenticated}
                studentId={studentId}
                openLoginModal={openLoginModal}
                openStudentModal={openStudentModal}
              />
            ))}
            {courseData.chapters.length === 0 ? (
              <p className='text-center text-teal-600 text-lg font-semibold py-6'>
                {language === 'en' 
                  ? 'Chapters will be uploaded very soon.' 
                  : 'अध्याय बहुत जल्द अपलोड किए जाएंगे।'}
              </p>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
          <span className="ml-3 text-lg text-gray-700">
            {language === 'en' ? 'Loading...' : 'लोड हो रहा है...'}
          </span>
        </div>
      )}
    </div>
  );
};

export default CourseDetailPage;