import React from 'react';
import { useNavigate } from 'react-router-dom';

const WelcomeComponent = () => {
  const navigate = useNavigate();
  
  const handleGetStarted = () => {
    navigate('/TalentTest2025/GetLink');
  };
  
  return (
    <div className="flex flex-col items-center justify-center w-full mx-auto py-12 px-4 bg-gradient-to-r from-purple-700 to-indigo-900 rounded-xl shadow-2xl max-w-4xl">
      <div className="text-center mb-8">
        <h1 className="text-5xl font-bold text-white mb-4">
          NAE TALENT TEST 2025
        </h1>
        <div className="flex items-center justify-center mb-6">
          <span className="px-4 py-2 bg-yellow-400 text-black font-bold rounded-lg text-xl animate-pulse">
            WIN $5,000 CASH + A PREMIUM BAG + FREE ENGLISH COURSE
          </span>
        </div>
        <p className="text-xl text-white mb-8 max-w-2xl mx-auto">
          Showcase your talent and compete for amazing prizes! Join thousands of participants in this year's most exciting talent competition.
        </p>
      </div>
      
      <div className="relative">
        <button 
          onClick={handleGetStarted}
          className="px-8 py-4 bg-white text-purple-800 font-bold rounded-full text-xl hover:bg-yellow-400 hover:text-black transition-all duration-300 transform hover:scale-105 shadow-lg"
        >
          GET YOUR TEST LINK NOW
        </button>
        <div className="absolute -top-4 -right-2">
          <div className="animate-bounce bg-red-500 text-white text-xs px-2 py-1 rounded-full">
            Limited Time!
          </div>
        </div>
      </div>
      
      <div className="flex items-center justify-center mt-12 space-x-8">
        <div className="text-center">
          <div className="text-3xl font-bold text-white">1000+</div>
          <div className="text-sm text-gray-200">Participants</div>
        </div>
        <div className="h-12 w-px bg-gray-400"></div>
        <div className="text-center">
          <div className="text-3xl font-bold text-white">$5,000</div>
          <div className="text-sm text-gray-200">Cash Prize</div>
        </div>
        <div className="h-12 w-px bg-gray-400"></div>
        <div className="text-center">
          <div className="text-3xl font-bold text-white">0</div>
          <div className="text-sm text-gray-200">Days Left</div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeComponent;