import React, { useState, useEffect } from 'react';
import { CheckCircle2, XCircle } from 'lucide-react';
import RankingList from './RankingList';
import ClearDataButton from './CleanDataButton';

type Subject = {
  total: number;
  answered: number;
};

type Subjects = {
  [key: string]: Subject;
};

type Answers = {
  [key: string]: { [key: number]: number };
};

type MarkedForReview = {
  [key: string]: number[];
};

interface AnalysisDetail {
  attempted: boolean;
  your_answer: number | null;
  correct_answer: number;
  correct: boolean | null;
  reason: string | null;
}

interface AnalysisData {
  [subject: string]: {
    [questionNumber: string]: AnalysisDetail;
  };
}

const STORAGE_KEYS = {
  testData: 'testData',
  responseData: 'responseData',
  subjects: 'subjects',
  answers: 'answers',
  markedForReview: 'markedForReview',
  testId: 'testId',
};

class TestResults extends React.Component<{
  analysis: AnalysisData;
  subjects: Subjects;
  testData: any;
}> {
  render() {
    const { analysis, subjects, testData } = this.props;

    // Compute overall numbers using the analysis data
    let totalQuestions = 0;
    let correctCount = 0;



    Object.keys(analysis).forEach((subject) => {
      const subjectData = analysis[subject];
      if (subjectData && typeof subjectData === 'object') {
        Object.keys(subjectData).forEach((qKey) => {
          totalQuestions++;
          if (subjectData[qKey]?.correct) {
            correctCount++;
          }
        });
      }
    });

    const score = totalQuestions > 0 ? ((correctCount / totalQuestions) * 100).toFixed(1) : "0";
    
    return (
      <div className="w-full max-w-4xl mx-auto p-2 space-y-6">
        <h1 className="text-2xl font-bold">Test Results</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-white rounded-lg shadow p-4 text-center">
            <h3 className="text-2xl font-bold text-blue-600">{score}%</h3>
            <p className="text-gray-600">Overall Score</p>
          </div>
          <div className="bg-white rounded-lg shadow p-4 text-center">
            <h3 className="text-2xl font-bold text-green-600">{correctCount}/{totalQuestions}</h3>
            <p className="text-gray-600">Correct Answers</p>
          </div>
          <div className="bg-white rounded-lg shadow p-4 text-center">
            <h3 className="text-2xl font-bold text-red-600">{totalQuestions - correctCount}/{totalQuestions}</h3>
            <p className="text-gray-600">Incorrect Answers</p>
          </div>
        </div>

        <RankingList testID={4} />

        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Detailed Analysis</h2>
          <div className="space-y-4">
            {Object.keys(analysis).map((subject) => {
              const subjectData = analysis[subject];
              if (!subjectData || typeof subjectData !== 'object') return null;

              // Find the subject in testData
              const testSubject = testData?.subjects?.find((s: any) => s.name === subject);
              return (
                <div key={subject} className="space-y-6">
                  <h3 className="font-medium text-lg">{subject}</h3>
                  {Object.keys(subjectData)
                    .sort((a, b) => Number(a) - Number(b))
                    .map((qNumStr, indexOf) => {
                      const qData = subjectData[qNumStr];
                      if (!qData) return null;

                      const questionId = Number(qNumStr);
                      const questionObj = testSubject
                        ? testSubject.questions.find((q: any) => q.id === questionId)
                        : null;
                      const questionText = questionObj
                        ? questionObj.question_text
                        : `Question ${qNumStr}`;

                      const yourAnswerOption =
                        qData.your_answer != null && questionObj
                          ? questionObj.options[qData.your_answer]
                          : "Not Answered";
                      const correctAnswerOption = questionObj
                        ? questionObj.options[qData.correct_answer]
                        : "N/A";

                      return (
                        <div key={qNumStr} className="p-2 border rounded-lg">
                          <div className="flex items-start gap-3">
                            {qData.attempted ? (
                              qData.correct ? (
                                <CheckCircle2 className="w-6 h-6 text-green-500 flex-shrink-0" />
                              ) : (
                                <XCircle className="w-6 h-6 text-red-500 flex-shrink-0" />
                              )
                            ) : (
                              <XCircle className="w-6 h-6 text-blue-500 flex-shrink-0" />
                            )}
                            <div>
                              {/* <p className="font-medium">Question {indexOf + 1}</p> */}
                              <p className="font-medium">{`Question ${indexOf + 1}: ${questionText}`}</p>
                              <p className="mt-1">
                                {qData.attempted
                                  ? `Your Answer: Option ${qData.your_answer ? qData.your_answer + 1 : 1} :  ${yourAnswerOption} ${qData.correct ? "(Correct)" : "(Incorrect)"}`
                                  : "Not Attempted"}
                              </p>
                              <p className="text-green-600">
                                Correct Answer: Option {qData.correct_answer + 1} :  {correctAnswerOption}
                              </p>
                              {qData.reason && (
                                <p className="text-gray-600 mt-2">
                                  Explanation: {qData.reason}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

class AttemptAnalysis extends React.Component {
  state = {
    analysis: {} as AnalysisData,
    subjects: {} as Subjects,
    testData: null as any,
    answers: {} as Answers,
    testId: Number,
    markedForReview: {} as MarkedForReview,
    showResults: false,
  };

  

  

  componentDidMount() {
    // Load all data from localStorage
    const savedAnalysis = localStorage.getItem(STORAGE_KEYS.responseData);
    const savedSubjects = localStorage.getItem(STORAGE_KEYS.subjects);
    const savedTestData = localStorage.getItem(STORAGE_KEYS.testData);
    const savedAnswers = localStorage.getItem(STORAGE_KEYS.answers);
    const savedMarkedForReview = localStorage.getItem(STORAGE_KEYS.markedForReview);
    const testId = localStorage.getItem(STORAGE_KEYS.testId);
    
    if (savedAnalysis) {
      try {
        const parsedAnalysis = JSON.parse(savedAnalysis);
        this.setState({ analysis: parsedAnalysis.analysis });
        // console.log(parsedAnalysis);
        
      } catch (error) {
        console.error("Error parsing analysis data:", error);
      }
    }
    if (savedSubjects) {
      try {
        this.setState({ subjects: JSON.parse(savedSubjects) || {} });
      } catch (error) {
        console.error("Error parsing subjects data:", error);
      }
    }
    if (savedTestData) {
      try {
        this.setState({ testData: JSON.parse(savedTestData) || null });
      } catch (error) {
        console.error("Error parsing test data:", error);
      }
    }
    if (savedAnswers) {
      try {
        this.setState({ answers: JSON.parse(savedAnswers) || {} });
      } catch (error) {
        console.error("Error parsing answers data:", error);
      }
    }
    if (savedMarkedForReview) {
      try {
        this.setState({ markedForReview: JSON.parse(savedMarkedForReview) || {} });
      } catch (error) {
        console.error("Error parsing markedForReview data:", error);
      }
    }

    // Set timeout to show results after 3 minutes (180000 ms)
    setTimeout(() => {
      this.setState({ showResults: true });
    }, 10000);

    // Refresh component once after 5 seconds
    setTimeout(() => {
      this.setState({ componentRefreshed: true });
    }, 5000);
  }

  

  render() {
    const { analysis, subjects, testData, showResults } = this.state;

    if (!showResults) {
      return (
        <div className="w-full max-w-4xl mx-auto p-3 space-y-6">
          <ClearDataButton />
          <h1 className="text-2xl font-bold">Test Submission Analysis</h1>
          <div className="bg-white rounded-lg shadow-lg p-6 space-y-6">
            <h2 className="text-xl font-semibold mb-4">Question Attempt Summary</h2>
            <div className="space-y-6">
              {Object.keys(subjects).map((subject) => (
                <div className="border-b pb-4" key={subject}>
                  <h3 className="font-medium mb-3">{subject}</h3>
                  <div className="grid grid-cols-3 gap-4 text-center">
                    <div>
                      <p className="text-sm text-gray-500">Total Questions</p>
                      <p className="text-lg font-medium">{subjects[subject].total}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Attempted</p>
                      <p className="text-lg font-medium text-green-600">
                        {Object.keys(this.state.answers[subject] || {}).length || 0}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Unattempted</p>
                      <p className="text-lg font-medium text-red-600">
                        {subjects[subject].total - (Object.keys(this.state.answers[subject] || {}).length || 0)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center text-gray-500 mt-4">
              Results will be available in 3 minutes
            </div>
          </div>
        </div>
      );
    }
        
    return (
      <TestResults
        analysis={analysis}
        subjects={subjects}
        testData={testData}
      />
    );
  }
}

export default AttemptAnalysis;